import { inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserTenant } from '@core/user-tenant/user-tenant.reducer';

@Injectable({
  providedIn: 'root',
})
export class UserTenantService {
  private readonly http = inject(HttpClient);
  private readonly config = inject<AppConfig>(APP_CONFIG);
  private readonly baseUrl = this.config.apiBaseUrl;

  listUserTenants$(): Observable<UserTenant[]> {
    return this.http.get<UserTenant[]>(`${this.baseUrl}/security/user-tenants`);
  }
}
