import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-update-available-snack',
    template: `
  <div>
    <p>An update is available. Would you like to update now?</p>
    <button mat-raised-button (click)="dismiss()">Dismiss</button>
    <button mat-raised-button (click)="update()" style="margin-left: 1em">Update Now</button>
  </div>
  `,
    styles: [],
    standalone: true,
    imports: [MatButton],
})
export class UpdateAvailableSnackComponent {

  constructor(public matSnackBarRef: MatSnackBarRef<UpdateAvailableSnackComponent>) {}

  update() {
    this.matSnackBarRef.dismissWithAction();
  }

  dismiss() {
    this.matSnackBarRef.dismiss();
  }
}
