import { inject, Injectable } from '@angular/core';
import { CgccStompService } from '@app/core/services/cgcc-stomp.service';
import { augmentWithMilliTimestamp, RawGpsMessage } from '@cco/model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import {
  GetLatestGPSDataForTruck,
  ProcessGPSDataForTruck,
  SubscribeToGPSForTruck,
  UnsubscribeFromGPSForTruck,
} from './truck-live-data.actions';
import { notNullish } from '@app/shared/rxjs/operators/standard-operators';
import { Store } from '@ngrx/store';
import { concatLatestFrom } from '@ngrx/operators';
import { userTenantFeature } from '@core/user-tenant/user-tenant.reducer';

@Injectable()
export class TruckLiveDataEffects {
  private readonly store = inject(Store);
  constructor(private actions$: Actions, private cgccStompService: CgccStompService) {}

  getLatestGpsDataForAllTrucks$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(SubscribeToGPSForTruck),
      concatLatestFrom(() => this.store.select(userTenantFeature.selectSelectedTenantId).pipe(notNullish())),
      mergeMap(([ action, tenantId ]) => {
        return this.cgccStompService.watch(`/app-topic/o/${tenantId}/trucks/${action.truckUuid}/gps/live`).pipe(
          takeUntil(
            // eslint-disable-next-line @ngrx/avoid-cyclic-effects
            this.actions$.pipe(
              ofType(UnsubscribeFromGPSForTruck),
              filter(unSubAction => unSubAction.truckUuid === action.truckUuid),
            ),
          ),
          map((stompMessage) => {
            const destination = stompMessage.headers.destination;
            const truckUuidMatch = destination.match(
              /\/app-topic\/o\/([^/]+)\/trucks\/([a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+-[a-f0-9]+)\/gps\/live/,
            );
            if (!truckUuidMatch) {
              console.error('Could not extract truck UUID from STOMP message destination');
            }
            const truckUuid = truckUuidMatch[2];
            const stompMessageBody = JSON.parse(stompMessage.body);

            if (stompMessageBody.constructor.name === 'Array') {
              return stompMessageBody.map((rawGpsMessage: RawGpsMessage) => ({
                ...augmentWithMilliTimestamp(rawGpsMessage),
                truckUuid,
              })) as RawGpsMessage[];
            }

            return [{ ...augmentWithMilliTimestamp(stompMessageBody), truckUuid }];
          }),
          map(gpsMessage =>
            ProcessGPSDataForTruck({ truckUuid: action.truckUuid, gpsData: gpsMessage }),
          ),
        );
      }),
    ); },
  );

  getLatestGPSDataForTruck$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(GetLatestGPSDataForTruck),
        tap(() =>
          this.cgccStompService.publish({
            destination: ``,
          }),
        ),
      ); },
    { dispatch: false },
  );
}
