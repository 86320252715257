import {
  Component,
  computed,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  Signal,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AboutDialogComponent } from '@app/modules/about-dialog/components/about-dialog/about-dialog.component';
import { selectActiveMediaQueries, selectMediaQueryIsActive } from '@app/store/reducers';
import { User } from '@cco/model';
import { auth2Actions } from '@core/auth2/store/auth2.actions';
import { selectAuthenticated } from '@core/auth2/store/auth2.reducer';
import * as Sentry from '@sentry/browser';
import { merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, share, takeUntil, tap } from 'rxjs/operators';
import { MediaStateSerializerService } from '../../services/media-state-serializer.service';
import { Go } from '../../store/actions/router.actions';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { StandardContainerComponent } from '@app/shared/containers/standard-container/standard-container.component';
import { ENTITY_SERVICE, EntityService } from '@core/services/entity.service';
import {
  VolumeCalculatorComponent,
} from '@modules/volume-calculator/components/volume-calculator/volume-calculator.component';
import { ServiceWorkerUpdateService } from '@app/core/services/service-worker-update.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  UpdateAvailableSnackComponent,
} from '@app/core/components/update-available-snack/update-available-snack.component';
import {
  ErrorReportingDialogComponent,
  ErrorReportingDialogData,
  ErrorReportingDialogResult,
} from '@modules/error-reporting-dialog/error-reporting-dialog.component';
import { notNullish } from '@app/shared/rxjs/operators/standard-operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StatusBarComponent } from '../../components/status-bar/status-bar.component';
import { SideNavMenuItemComponent } from '../side-nav-menu-item/side-nav-menu-item.component';
import { SideNavExpandableMenuComponent } from '../side-nav-expandable-menu/side-nav-expandable-menu.component';
import { MatTooltip } from '@angular/material/tooltip';
import {
  ConnectionMonitorComponent,
} from '@modules/connection-monitor/containers/connection-monitor/connection-monitor.component';
import { MatDivider } from '@angular/material/divider';
import {
  IfExternalAccountingEnabledDirective,
} from '@modules/accounting/if-external-accounting-enabled/directives/if-external-accounting-enabled.directive';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { IfHasPrivilegesDirective } from '@modules/auth-utils/directives/if-has-privileges.directive';
import { MatTabLink, MatTabNav, MatTabNavPanel } from '@angular/material/tabs';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatButton, MatIconAnchor, MatIconButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import { AsyncPipe, NgIf } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { selectCurrentUser } from '@core/auth2/store/auth.selectors';
import { userTenantFeature } from '@core/user-tenant/user-tenant.reducer';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ],
  standalone: true,
  imports: [
    FlexModule,
    NgIf,
    MatToolbar,
    MatAnchor,
    RouterLink,
    MatIcon,
    ExtendedModule,
    MatIconAnchor,
    BreadcrumbsComponent,
    MatTabNav,
    MatTabLink,
    IfHasPrivilegesDirective,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    IfExternalAccountingEnabledDirective,
    MatDivider,
    ConnectionMonitorComponent,
    MatButton,
    MatIconButton,
    MatTooltip,
    MatSidenavContainer,
    MatSidenav,
    SideNavExpandableMenuComponent,
    SideNavMenuItemComponent,
    MatSidenavContent,
    MatTabNavPanel,
    RouterOutlet,
    StatusBarComponent,
    AsyncPipe,
  ],
})
export class LayoutComponent extends StandardContainerComponent implements OnInit, OnDestroy {
  @ViewChild('mainLayout') mainLayoutEl: ElementRef<HTMLDivElement>;
  @ViewChild('helpSidenav') helpSidenav: MatSidenav;
  @ViewChild('sidenavToggleBar') helpSidenavToggleDiv: ElementRef<HTMLDivElement>;

  readonly appName: string;
  readonly title: string;

  currentUser: Signal<User>;
  givenName: Signal<string>;

  authenticated: boolean;
  authenticated$: Observable<boolean>;

  sidenavOpened = false;
  showBackNavButton$: Observable<boolean>;
  backButtonLabel$: Observable<string>;
  breadcrumbSegments$: Observable<number>;

  isMobile = false;

  panRight$ = new Subject<any>();
  panEndSource$ = new Subject<any>();
  panEnd$ = this.panEndSource$.pipe(share());
  swipeRight$ = new Subject<any>();
  panDown$ = new Subject<any>();
  swipeDown$ = new Subject<any>();

  protected readonly userHasAccessToMoreThanOneTenant = this.store.selectSignal(userTenantFeature.selectUserHasAccessToMoreThanOneTenant);

  constructor(
    @Inject(APP_CONFIG) config: AppConfig,
    public dialog: MatDialog,
    protected mediaStateSerializer: MediaStateSerializerService, // Needs to be injected someone so that Angular will construct it
    protected renderer: Renderer2,
    @Inject(ENTITY_SERVICE) protected entityService: EntityService,
    private updateService: ServiceWorkerUpdateService,
    protected snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
  ) {
    super();

    this.appName = config.appName;
    this.title = config.appName;

    this.store.select(selectMediaQueryIsActive({ mediaQuery: 'lt-md' })).pipe(
        takeUntil(this.destroyed$),
        map(active => (this.isMobile = active)),
      )
      .subscribe();

    this.currentUser = this.store.selectSignal(selectCurrentUser);
    this.givenName = computed(() => this.currentUser()?.givenName);
  }

  ngOnInit() {
    this.authenticated$ = this.store.select(selectAuthenticated);
    this.authenticated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(authenticated => (this.authenticated = authenticated));
    this.breadcrumbSegments$ = this.store.select(selectActiveMediaQueries).pipe(
      takeUntil(this.destroyed$),
      map((activeMediaQueries) => {
        if (activeMediaQueries.includes('xs')) {
          return 2;
        }

        if (activeMediaQueries.includes('sm')) {
          return 3;
        }

        if (activeMediaQueries.includes('md')) {
          return 4;
        }

        return null;
      }),
    );
    // Delay the loading of customers to wait for the store to be rehydrated
    setTimeout((() => this.entityService.loadIfOlderThan('Customer', 'P1D')).bind(this), 750);

    // Handle swiping to the right to navigate back
    merge(
      this.panRight$,
      this.panEnd$,
      this.swipeRight$.pipe(
        tap(() => {
          let node = this.route.snapshot;
          while (node.firstChild) {
            node = node.firstChild;
          }
          if (node.data && node.data.backPath) {
            this.back(node.data.backPath);
          } else {
            this.back();
          }
        }),
      ),
    )
      .pipe(
        tap((gesture) => {
          if (this.mainLayoutEl) {
            const nativeElement = this.mainLayoutEl.nativeElement;

            if (gesture.type === 'panend' || gesture.type === 'swiperight') {
              this.renderer.removeStyle(nativeElement, 'transform');
            } else if (gesture.type === 'panright') {
              this.renderer.setStyle(nativeElement, 'transform', `translate(${gesture.deltaX}px)`);
            }
          }
        }),
      )
      .subscribe();

    // Handle swiping down to refresh
    merge(this.panDown$, this.panEnd$, this.swipeDown$).subscribe();
    /*
    this.showBackNavButton$ = this.store.pipe(
      select(selectRouterRouteData),
      takeUntil(this.destroyed$),
      map(routeData => {
        for (let i = 0; i < routeData.length; i++) {
          if (routeData[i].showBackButton) {
            return true;
          }
        }

        return false;
      }),
      shareReplay()
    );
    */
    /*
    this.backButtonLabel$ = this.store.pipe(
      select(selectRouterRouteData),
      takeUntil(this.destroyed$),
      map(routeData => {
        const leafRouteData = routeData[routeData.length - 1];
        return leafRouteData && leafRouteData.backLabel;
      })
    );
    this.label$ = this.store.pipe(
      select(selectRouterRouteData),
      takeUntil(this.destroyed$),
      map(routeData => {
        const leafRouteData = routeData[routeData.length - 1];
        return leafRouteData && leafRouteData.label;
      })
    );
    */
    // this.routingLabels$.subscribe();
    /*
    fromEvent(window, 'scroll').pipe(
      takeUntil(this.destroyed$),
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      tap(p => console.log(p))
    ).subscribe();
    */

    this.updateService.eventLog$.pipe(
      map(eventLog => eventLog.findIndex(event => event.type === 'VERSION_READY') !== -1),
      distinctUntilChanged(),
    ).subscribe((updateAvailable) => {
      if (updateAvailable) {
        this.snackBar.openFromComponent(UpdateAvailableSnackComponent).onAction().pipe(takeUntil(this.destroyed$)).subscribe(() => this.updateService.updateApp());
      }
    });
  }

  openVolumeCalculatorDialog() {
    this.dialog.open(VolumeCalculatorComponent);
  }

  checkCorrelationId(prev: any, cur: any): boolean {
    return prev.payload.correlationId === cur.payload.correlationId;
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  toggleSidenav() {
    this.sidenavOpened = !this.sidenavOpened;
  }

  closeSidenav() {
    this.sidenavOpened = false;
  }

  isLinkActive(linkName: string) {
    const snapshot = this.route.snapshot;
    if (
      snapshot.firstChild &&
      snapshot.firstChild.url[0] &&
      snapshot.firstChild.url[0].path === linkName
    ) {
      return true;
    }

    return false;
  }

  onLogin() {
    this.store.dispatch(auth2Actions.authenticateUser());
  }

  onLogout() {
    this.store.dispatch(auth2Actions.logout());
    // this.store.dispatch(Go({ path: [ '/app/auth2/logout' ]}));
  }

  printPage() {
    window.print();
  }

  onAbout() {
    this.dialog.open(AboutDialogComponent);
  }

  back(commands: any[] = ['..']) {
    let leaf = this.route;
    while (leaf.firstChild) {
      leaf = leaf.firstChild;
    }
    this.store.dispatch(Go({ path: commands, extras: { relativeTo: leaf } }));
  }

  onSwipeRight($event) {
    if (this.isMobile) {
      this.swipeRight$.next($event);
    }
  }

  onPanRight($event) {
    if (this.isMobile) {
      this.panRight$.next($event);
    }
  }

  onPanEnd($event) {
    if (this.isMobile) {
      this.panEndSource$.next($event);
    }
  }

  onPanDown($event) {
    if (this.isMobile) {
      this.panDown$.next($event);
    }
  }

  onSwipeDown($event) {
    if (this.isMobile) {
      this.swipeDown$.next($event);
    }
  }

  showHelpSidenav() {
    if (this.helpSidenav) {
      this.helpSidenav.open();
    }
    if (this.helpSidenavToggleDiv) {
      this.helpSidenavToggleDiv.nativeElement.style.display = 'none';
    }
  }

  hideHelpSidenav() {
    if (this.helpSidenav) {
      this.helpSidenav.close();
    }
    if (this.helpSidenavToggleDiv) {
      this.helpSidenavToggleDiv.nativeElement.style.display = 'block';
    }
  }

  reportIssue() {
    const dialogRef = this.dialog.open<
      ErrorReportingDialogComponent,
      ErrorReportingDialogData,
      ErrorReportingDialogResult
    >(ErrorReportingDialogComponent, { data: {} });
    dialogRef.afterClosed()
      .pipe(notNullish())
      .subscribe((result) => {
        const eventId = Sentry.captureMessage('user-reported-error');
        Sentry.captureUserFeedback({
          event_id: eventId,
          name: '',
          email: '',
          comments: result.description,
        });
      });
  }
}
