import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { sendNotification, sendNotificationFailure, sendNotificationSuccess } from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(private actions$: Actions, private notificationService: NotificationService) {}

  sendNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendNotification),
      concatMap((action) => {
        return this.notificationService.sendNotification$(action.target, action.message).pipe(
          map(() => sendNotificationSuccess({requestId: action.requestId})),
          catchError(error => of(sendNotificationFailure({requestId: action.requestId, error}))),
        );
      }),
    );
  });
}
