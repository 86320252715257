import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpRequest } from '@angular/common/http';
import { JwtInterceptor } from './jwt.interceptor';
import { JWT_OPTIONS } from './jwtoptions.token';
import { JwtHelperService } from './jwthelper.service';
import { Observable } from 'rxjs';

export interface JwtInterceptorRule {
  baseUrl: string;
  aud: string,
  getToken$: (aud: string) => Observable<string>;
}

export interface JwtConfig {
  headerName?: string;
  authScheme?: string | ((request?: HttpRequest<any>) => string);
  rules: JwtInterceptorRule[];
  throwNoTokenError?: boolean;
  skipWhenExpired?: boolean;
}

export interface JwtModuleOptions {
  jwtOptionsProvider?: Provider;
  config?: JwtConfig;
}

export { JWT_OPTIONS };

@NgModule()
export class JwtModule {
  constructor(@Optional() @SkipSelf() parentModule: JwtModule) {
    if (parentModule) {
      throw new Error(
        "JwtModule is already loaded. It should only be imported in your application's main module."
      );
    }
  }
  static forRoot(options: JwtModuleOptions): ModuleWithProviders<JwtModule> {
    return {
      ngModule: JwtModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true,
        },
        options.jwtOptionsProvider || {
          provide: JWT_OPTIONS,
          useValue: options.config,
        },
        JwtHelperService,
      ],
    };
  }
}
