import { Component, Inject, NgZone, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AboutBackend } from '@app/core/models/about-backend';
import { AboutService } from '@app/core/services/about.service';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { licenseInfo } from './license-info';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { ServiceWorkerUpdateService } from '@core/services/service-worker-update.service';
import * as Sentry from '@sentry/angular';
import { map } from 'rxjs/operators';
import { ConfirmDialogComponent } from '@modules/confirm-dialog/components/confirm-dialog/confirm-dialog.component';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout/flex';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { StateViewerDialogComponent } from '@modules/state-viewer/state-viewer-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { IfHasPrivilegesDirective } from '@modules/auth-utils/directives/if-has-privileges.directive';

@Component({
    selector: 'app-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss'],
    standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    FlexModule,
    MatButton,
    MatProgressSpinner,
    MatAccordion,
    NgFor,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatDialogActions,
    MatDialogClose,
    AsyncPipe,
    MatIcon,
    IfHasPrivilegesDirective,
  ],
})
export class AboutDialogComponent implements OnInit {
  location = location;
  aboutBackend$: Observable<AboutBackend>;
  licenses = licenseInfo;
  showCommitIds = false;
  thisYear = DateTime.local().year;
  updateAvailable$: Observable<boolean>;
  checkingForUpdates = false;
  updating = false;

  get serviceWorkerController() {
    return navigator.serviceWorker?.controller;
  }

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private aboutBackendService: AboutService,
    private updateService: ServiceWorkerUpdateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private ngZone: NgZone,
  ) {
  }

  ngOnInit() {
    this.aboutBackend$ = this.aboutBackendService.getAboutBackend();
    this.updateAvailable$ = this.updateService.eventLog$.pipe(
      map(eventLog => eventLog.findIndex(event => event.type === 'VERSION_READY') !== -1),
    );
  }

  onClearLocalStorage() {
    localStorage.clear();

    this.ngZone.runOutsideAngular(() => {
      const request = indexedDB.deleteDatabase('cco');
      request.onsuccess = () => {
        this.snackBar.open(
          'Successfully cleared local data.  Please reload Concrete CloudOps™ in your browser or force quit and restart the app.',
        );
      };
      request.onerror = () => {
        this.snackBar.open('An error occurred while clearing local data.  ' + request.error);
      };
      request.onblocked = () => {
        this.snackBar.open('Queued request to clear local data.  Please close all Concrete CloudOps™ tabs and reload in your browser or force quit and restart the app.');
      };
    });
  }

  checkForUpdates() {
    this.checkingForUpdates = true;
    this.updateService.checkForUpdate()
      .then((updates) => {
        if (!updates)
          this.snackBar.open(`There are no updates currently available`, 'Close', { duration: 3000 });

        this.checkingForUpdates = false;
      })
      .catch((err) => {
        Sentry.captureException(err);
        this.snackBar.open('An error occurred while checking for updates . . .', 'Close');
      });
  }

  update() {
    this.updateService.updateApp();
  }

  unregisterServiceWorkers() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Unregister Service Worker?',
        message: 'Are you sure you want to unregister all Concrete CloudOps™ Service Workers from this browser?',
        button1: {
          label: 'Yes',
          icon: 'check',
          result: true,
        },
        button2: {
          label: 'Cancel',
          result: false,
          focusInitial: true,
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result)
        return;

      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister().then((success) => {
            if (success) {
              this.snackBar.open('Successfully unregistered Service Worker.  Please close all Concrete CloudOps™ tabs, and browse to it in a new tab and reload it.', 'Close');
            } else {
              this.snackBar.open('Failed to unregister Service Worker.  Please contact support.', 'Close');
            }
          });
        }
      });
    });
  }

  protected showStateViewerDialog() {
    this.dialog.open<StateViewerDialogComponent>(StateViewerDialogComponent, {
      minWidth: '85vw',
      maxHeight: '100vh',
    });
  }
}
