import { ErrorHandler } from '@angular/core';

export class MyErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any) {
    super.handleError(error);
  }
}
