import { Injectable } from '@angular/core';
import { DefaultPersistenceResultHandler, EntityAction } from '@ngrx/data';
import { Action } from '@ngrx/store';

@Injectable()
export class CcoPersistenceResultHandler extends DefaultPersistenceResultHandler {
  handleSuccess(originalAction: EntityAction): (data: any) => Action {
    const actionHandler = super.handleSuccess(originalAction);

    return function (data: any) {
      const successAction = actionHandler.call(this, data);

      if (successAction && data && data.items) {
        (successAction as any).payload.data = data.items;
        (successAction as any).payload.lastEvaluatedKey = data.lastEvaluatedKey;
      }

      // TODO: add full pagination support
      if (successAction && data && data.content) {
        (successAction as any).payload.data = data.content;
        (successAction as any).paged = true;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { content, ...pageInfo } = data;
        (successAction as any).pageInfo = pageInfo;
      }

      return successAction;
    };
  }
}
