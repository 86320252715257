import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { StandardService } from '@app/shared/services/standard.service';
import { Assignment } from '@cco/model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'any'
})
export class AssignmentService extends StandardService<Assignment, number> {
  constructor(@Inject(APP_CONFIG) private readonly config: AppConfig, protected http: HttpClient) {
    super(http, config.apiBaseUrl, '');
  }

  public transitionAssignmentState(
    id: number,
    transitionId: number,
    reason: string,
    yardId: number = null,
  ): Observable<Assignment> {
    const httpParams: { [key: string]: string } = { transitionId: `${transitionId}`, reason };
    if (yardId != null) {
      httpParams.yardId = `${yardId}`;
    }
    return this.tenantBaseUrl$.pipe(
      switchMap(baseUrl => this.http
      .post<Assignment>(`${baseUrl}/assignment/${id}/transition`, '', { params: httpParams })
      .pipe(map(response => this.mapModelObject.bind(this)(response)))));
  }

  public undoStateTransition(
    id: number,
    targetStateId: number,
    reason: string,
  ): Observable<Assignment> {
    return this.tenantBaseUrl$.pipe(
      switchMap(baseUrl => this.http
      .post<Assignment>(`${baseUrl}/assignment/${id}/transition/undo`, '', {
        params: { targetStateId: `${targetStateId}`, reason },
      })
      .pipe(map(response => this.mapModelObject.bind(this)(response)))));
  }
}
