import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface GetIdRequest {
  IdentityPoolId: string;
  Logins: { [provider: string]: string }
}

export interface GetIdResponse {
  IdentityId: string;
}

export interface GetCredentialsForIdentityRequest {
  CustomRoleArn?: string;
  IdentityId: string;
  Logins: { [provider: string]: string }
}

export interface GetCredentialsForIdentityResponse {
  Credentials: {
    AccessKeyId: string;
    Expiration: number;
    SecretKey: string;
    SessionToken: string;
  }
  IdentityId: string;
}

@Injectable({
  providedIn: 'any'
})
export class CognitoIdentityService {
  private readonly http = inject(HttpClient);

  public getId$(request: GetIdRequest): Observable<GetIdResponse> {
    const region = request.IdentityPoolId.split(':')[0];
    return this.http.post<GetIdResponse>(`https://cognito-identity.${region}.amazonaws.com/`,
      request,
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'x-amz-target': 'AWSCognitoIdentityService.GetId',
        },
      });
  }

  public getCredentialsForIdentity$(request: GetCredentialsForIdentityRequest): Observable<GetCredentialsForIdentityResponse> {
    const region = request.IdentityId.split(':')[0];
    return this.http.post<GetCredentialsForIdentityResponse>(
      `https://cognito-identity.${region}.amazonaws.com/`,
      request,
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'x-amz-target': 'AWSCognitoIdentityService.GetCredentialsForIdentity',
        }
      }
    );
  }
}

export function isCognitoIdentityTokenExpiredException(err: any) {
  return err?.url?.startsWith('https://cognito-identity.')
    && err?.url?.endsWith('.amazonaws.com/')
    && err?.error?.message?.startsWith('Invalid login token. Token expired');
}
