<mat-menu #gpsStatusDetails class="gnss-details-panel">
  <span
    mat-menu-item
    style="font-family: Roboto, 'Helvetica Neue', sans-serif; margin-left: 1em; margin-right: 1em"
    matTooltip="Global Navigation Satellite System (GPS, GLONASS, etc.)"
    >GNSS Status</span
  >
  <mat-divider></mat-divider>
  <span mat-menu-item style="font-family: Roboto, 'Helvetica Neue', sans-serif; margin-left: 1em; margin-right: 1em"
    >Last Updated: {{ latestPos?.timestamp | timeAgo }}</span
  >
  <span mat-menu-item style="font-family: Roboto, 'Helvetica Neue', sans-serif; margin-left: 1em; margin-right: 1em"
    >Est. Accuracy: {{ latestPos?.coords?.accuracy | distance }}</span
  >
  <span mat-menu-item style="font-family: Roboto, 'Helvetica Neue', sans-serif; margin-left: 1em; margin-right: 1em"
    >Latitude: {{ latestPos?.coords?.latitude | number: '1.1-7' }}&deg;</span
  >
  <span mat-menu-item style="font-family: Roboto, 'Helvetica Neue', sans-serif; margin-left: 1em; margin-right: 1em"
    >Longitude: {{ latestPos?.coords?.longitude | number: '1.1-7' }}&deg;</span
  >
</mat-menu>
<button mat-icon-button [matMenuTriggerFor]="gpsStatusDetails" matTooltip="GNSS Status: {{ gpsStatus }}">
  <mat-icon *ngIf="gpsStatus === GpsStatuses.UNKNOWN">gps_off</mat-icon>
  <mat-icon *ngIf="gpsStatus === GpsStatuses.ERROR" color="accent">gps_off</mat-icon>
  <mat-icon *ngIf="gpsStatus === GpsStatuses.BAD_FIX">gps_not_fixed</mat-icon>
  <mat-icon *ngIf="gpsStatus === GpsStatuses.GOOD_FIX">gps_fixed</mat-icon>
</button>
