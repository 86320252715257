import { FormControlDirective, FormControlName } from '@angular/forms';
import { JwtConfig } from '@app/modules/angular-jwt/angular-jwt.module';
import { TokenService } from '@core/services/token.service';
import { config } from '@cco/apps/cco-frontend';

// https://plnkr.co/edit/WII8VvHB5iGbPWB2CcM3?p=preview
const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function(...args) {
  if (this.valueAccessor && this.valueAccessor._elementRef && this.form) {
    this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  }

  return originFormControlNgOnChanges.apply(this, args);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function(...args) {
  const result = originFormControlNameNgOnChanges.apply(this, args);
  if (this.valueAccessor && this.valueAccessor._elementRef) {
    this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  }
  return result;
};

export function jwtOptionsFactory(tokenService: TokenService): JwtConfig {
  return {
    rules: [
      {
        baseUrl: config.cloudApiBaseUrl,
        aud: config.cloudApiBaseUrl,
        getToken$: tokenService.getToken$.bind(tokenService),
      },
      {
        baseUrl: config.mailerBaseUrl,
        aud: config.mailerAud ?? config.mailerBaseUrl,
        getToken$: tokenService.getToken$.bind(tokenService),
      },
      {
        baseUrl: config.pdfExporterBaseUrl,
        aud: config.pdfExporterBaseUrl,
        getToken$: tokenService.getToken$.bind(tokenService),
      },
    ],
  };
}
