import { Injectable } from '@angular/core';
import { getFullRoute, getMobileRoute } from '@app/shared/utils/route-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SetActiveMediaQueries } from '../actions/media.actions';
import { Go } from '../actions/router.actions';
import { concatLatestFrom } from '@ngrx/operators';
import { selectRouterState } from '@app/store/reducers';

@Injectable()
export class MediaEffects {
  constructor(private actions$: Actions, private store: Store) {
  }

  setActiveMediaQueries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetActiveMediaQueries),
      map(action => action.activeMediaQueries),
      concatLatestFrom(() => this.store.select(selectRouterState)),
      switchMap(([activeMediaQueries, routerState]) => {
        const routeData = routerState.routeData;
        // const urlSegments = routerState.urlSegments;
        // console.log('activeMediaQueries, routeData', activeMediaQueries, routeData, urlSegments);

        if (activeMediaQueries.includes('lt-md')) {
          if (routeData.findIndex(routeDatum => routeDatum.mobilePath) !== -1) {
            return of(Go({path: getMobileRoute(routerState)}));
          }
        } else {
          if (routeData.findIndex(routeDatum => routeDatum.fullPath) !== -1) {
            return of(Go({path: getFullRoute(routerState)}));
          }
        }
        return EMPTY;
      }),
    );
  });
}
