import { HttpClient, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { APP_CONFIG } from '@cco/apps/cco-frontend';

export interface CsrfResponse {
  headerName: string;
  token: string;
  parametersName: string;
}

export function xsrfInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const baseUrl = inject(APP_CONFIG).apiBaseUrl;
  const http = inject(HttpClient);

  if (req.method === 'GET' || req.method === 'HEAD' || req.method === 'OPTIONS')
    return next(req);

  if (req.url.startsWith(baseUrl)
    || (baseUrl.startsWith('/') && req.url.startsWith(baseUrl.slice(1)))
  ) {
    return http.get<CsrfResponse>(baseUrl + '/security/csrf').pipe(
      switchMap((csrfResponse) => {
        const newReq = req.clone({
          headers: req.headers.set(csrfResponse.headerName, csrfResponse.token),
        });
        return next(newReq);
      }),
    );
  }

  return next(req);
}
