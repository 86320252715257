import { Injectable } from '@angular/core';
import { Log } from '@app/core/store/actions/logger.actions';
import { AssignmentService } from '@app/main/assignments/services/assignment.service';
import {
  TransitionAssignmentState,
  TransitionAssignmentStateFailure,
  TransitionAssignmentStateToLoading,
  TransitionAssignmentStateToLoadingFailure,
  UndoAssignmentStateTransition,
  UndoAssignmentStateTransitionFailure,
} from '@app/main/assignments/store/assignment.actions';
import { EntityActionFactory, EntityOp } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

@Injectable()
export class AssignmentEffects {
  private entityActionFactory: EntityActionFactory;

  constructor(private actions$: Actions, private assignmentService: AssignmentService) {
    this.entityActionFactory = new EntityActionFactory();
  }

  transitionAssignmentState$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(TransitionAssignmentState),
      concatMap(action =>
        this.assignmentService
          .transitionAssignmentState(action.id, action.transitionId, action.reason)
          .pipe(
            map(assignment =>
              this.entityActionFactory.create(
                'Assignment',
                EntityOp.SAVE_UPSERT_ONE_SUCCESS,
                assignment,
              ),
            ),
            catchError(error =>
              of(
                TransitionAssignmentStateFailure({ error }),
                Log({
                  level: 3,
                  source: `${this.constructor.name}.transitionAssignmentState$`,
                  message: `An error occurred while transitioning an assignment (id: ${action.id}) state using transition (id: ${action.transitionId}) with reason, '${action.reason}'`,
                  object: error,
                }),
              ),
            ),
          ),
      ),
    ); },
  );

  transitionAssignmentStateToLoading$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(TransitionAssignmentStateToLoading),
      concatMap(action =>
        this.assignmentService
          .transitionAssignmentState(action.id, action.transitionId, action.reason, action.yardId)
          .pipe(
            map(assignment =>
              this.entityActionFactory.create(
                'Assignment',
                EntityOp.SAVE_UPSERT_ONE_SUCCESS,
                assignment,
              ),
            ),
            catchError(error =>
              of(
                TransitionAssignmentStateToLoadingFailure({ error }),
                Log({
                  level: 3,
                  source: `${this.constructor.name}.transitionAssignmentStatToLoadinge$`,
                  message: `An error occurred while transitioning an assignment (id: ${action.id}) state using transition (id: ${action.transitionId}) with reason, '${action.reason}'`,
                  object: error,
                }),
              ),
            ),
          ),
      ),
    ); },
  );

  undoStateTransition$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(UndoAssignmentStateTransition),
      concatMap(action =>
        this.assignmentService
          .undoStateTransition(action.id, action.targetStateId, action.reason)
          .pipe(
            map(assignment =>
              this.entityActionFactory.create(
                'Assignment',
                EntityOp.SAVE_UPSERT_ONE_SUCCESS,
                assignment,
              ),
            ),
            catchError(error =>
              of(
                UndoAssignmentStateTransitionFailure({ error }),
                Log({
                  level: 3,
                  source: `${this.constructor.name}.undoStateTransition$`,
                  message: `An error occurred while undoing a state transition for an assignment (id: ${action.id}) with a target state of (id: ${action.targetStateId}) and a reason of, '${action.reason}'`,
                  object: error,
                }),
              ),
            ),
          ),
      ),
    ); },
  );
}
