import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable, retry, timer } from 'rxjs';
import * as Sentry from '@sentry/angular';

export function retryOnErrorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(req).pipe(
    retry({
      count: 5,
      delay: (err, retryCount) => {
        if (err instanceof HttpErrorResponse && [0, 503, 504].includes(err.status)) {
          Sentry.addBreadcrumb({
            type: 'http',
            category: 'xhr',
            message: 'Retrying failed HTTP request',
            level: 'info',
            data: {
              requestUrl: req.url,
              status: err.status,
            },
          });
          return timer(retryCount * 1000);
        }

        throw err;
      },
    }),
  );
}
