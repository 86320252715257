import { Pipe, PipeTransform } from '@angular/core';
import { Interval } from '@cco/model';
import { DateTime } from 'luxon';

@Pipe({
  name: 'lxDateRange',
  standalone: true,
})
/**
 * This pipe takes in an interval including start and end values that can be null and produces a string representation of the interval. '?' is used in place of null values.
 * E.g. {start: '2021-01-01T08:00:00', end: '2021-02-02T08:00:00} gives '1/1/2021 – 2/2/2021' and {start: null, end: null} gives '? – ?'
 */
export class LuxonDateRangePipe implements PipeTransform {
  transform(value: Interval, defaultValue = 'Invalid Date Range'): string {
    if (value == null || (value.start == null && value.end == null))
      return defaultValue;

    const startDateTime = DateTime.fromISO(value.start);
    const endDateTime = DateTime.fromISO(value.end);

    if (endDateTime.diff(startDateTime).toMillis() < 0)
      return 'Invalid Interval: start is after the end';

    const startString = startDateTime.isValid ? startDateTime.toLocaleString() : '?';
    const endString = endDateTime.isValid ? endDateTime.toLocaleString() : '?';

    return `${startString} – ${endString}`;
  }
}
