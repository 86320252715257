import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SettingsCoreDataModule } from '@main/settings/settings-core-data.module';
import { ResolvedSettingFetcherService } from '@main/settings/services/resolved-setting-fetcher.service';

@NgModule({
  imports: [
    CommonModule,
    SettingsCoreDataModule,
  ],
})
export class SettingsCoreModule {
  constructor(settingFetcher: ResolvedSettingFetcherService, @Optional() @SkipSelf() parentModule?: SettingsCoreModule) {
    if (parentModule)
      throw new Error('SettingsCoreModule should only be loaded once');

    settingFetcher.start();
  }
}
