import { PipeTransform, Pipe } from '@angular/core';

enum DistanceUnits {
    METERS,
    KILOMETERS
}

/**
 * Accepts meters
 */
@Pipe({
    name: 'distance',
    standalone: true
})
export class FormatDistancePipe implements PipeTransform {
    transform(value: number): string {
        if (!value) { return ''; }

        let out = '';

        let distance: number;
        let unit = DistanceUnits.METERS;

        if (value > 2000) {
            distance = value / 1000.0;
            unit = DistanceUnits.KILOMETERS;
        } else {
            distance = value;
        }

        out += distance.toFixed(2) + ' ';
        switch (unit) {
            case DistanceUnits.KILOMETERS:
                out += 'km';
                break;
            case DistanceUnits.METERS:
                out += 'm';
                break;
            default:
                throw new Error('This should never happen!');
        }

        return out;
    }
}
