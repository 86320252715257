import { EntityMetadataMap } from '@ngrx/data';
import { EntityUrlMap } from '@app/data/cco-entity-url-registry.service';

export const settingsEntityMetadata: EntityMetadataMap = {
  CompanyManagedSetting: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
  },
  CompanySettingValue: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
  },
  UserManagedSetting: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
  },
  UserSettingValue: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
  }
};

export const settingsEntityUrlMap: EntityUrlMap = {
  CompanyManagedSetting: {
    entityResourceUrl: 'settings/company-managed',
    collectionResourceUrl: 'settings/company-managed',
  },
  CompanySettingValue: {
    entityResourceUrl: 'company/settings',
    collectionResourceUrl: 'company/settings',
  },
  UserManagedSetting: {
    entityResourceUrl: 'settings/user-managed',
    collectionResourceUrl: 'settings/user-managed',
  },
  UserSettingValue: {
    entityResourceUrl: 'users/me/settings',
    collectionResourceUrl: 'users/me/settings',
  },
};
