<div class="flex justify-between status-bar" [class.fade-in]="shouldFadeIn" [style.background-color]="statusMap[connectionQuality]?.color">
  <div class="flex ml-2">
    <div class="flex gap-1 items-center text-white">
      <div class="hidden lg:block">
        Connection: {{ connectionQuality }}
      </div>
      <div class="block lg:hidden">
        <mat-icon>{{statusMap[connectionQuality]?.iconName}}</mat-icon>
      </div>
    </div>
  </div>
  <div class="flex mr-2">
    <div class="flex gap-1 items-center text-white">
      @if (stateViewerPreviouslyOpened$ | async) {
        <button *appIfHasPrivileges="['UI_VIEW_TOOLS_STATE_VIEWER']" mat-icon-button (click)="showStateViewerDialog()">
          <mat-icon fontSet="material-symbols-outlined">account_tree</mat-icon>
        </button>
      }
      <div class="hidden lg:block">Showing</div>
      <div>{{ form.value | lxDateRange:'all data' }}</div>
      <div class="cursor-pointer cco-global-date-range-filter-icon" (click)="onDateRangeClick()">
        <mat-icon class="text-lg">date_range</mat-icon>
      </div>
    </div>
  </div>
</div>
