import { Inject, Injectable, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateTime, Info, Settings } from 'luxon';

/*
// Configurable options for {@see LuxonDateAdapter}.
export interface MatLuxonDateAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * {@default false}
   */
/*
useUtc?: boolean;
}

// InjectionToken for Luxon date adapter to configure options.
export const MAT_LUXON_DATE_ADAPTER_OPTIONS = new InjectionToken<MatLuxonDateAdapterOptions>(
'MAT_LUXON_DATE_ADAPTER_OPTIONS', {
providedIn: 'root',
factory: MAT_LUXON_DATE_ADAPTER_OPTIONS_FACTORY
});


// @docs-private
export function MAT_LUXON_DATE_ADAPTER_OPTIONS_FACTORY(): MatLuxonDateAdapterOptions {
return {
  useUtc: false
};
}
*/

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}


/** Adapts Luxon DateTimes for use with Angular Material. */
@Injectable()
export class LuxonDateAdapter extends DateAdapter<DateTime> {
  // Note: all of the methods that accept a `DateTime` input parameter immediately call `this.clone`
  // on it. This is to ensure that we're working with a `DateTime` that has the correct locale setting.

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
    // @Optional() @Inject(MAT_LUXON_DATE_ADAPTER_OPTIONS) private _options?: MatLuxonDateAdapterOptions
  ) {
    super();
    this.setLocale(dateLocale || Settings.defaultLocale);
  }

  setLocale(locale: string) {
    super.setLocale(locale);
  }

  getYear(date: DateTime): number {
    return date.year;
  }

  getMonth(date: DateTime): number {
    return date.month - 1;
  }

  getDate(date: DateTime): number {
    return date.day;
  }

  getDayOfWeek(date: DateTime): number {
    return date.weekday % 7;
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return range(12, i => this.createDate(2017, i, 1).toLocaleString({ month: style }));
  }

  getDateNames(): string[] {
    return range(31, i => this.createDate(2017, 0, i + 1).toLocaleString({ day: 'numeric' }));
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const weekdays = Info.weekdays(style, { locale: this.locale });
    const sunday = weekdays.pop();
    weekdays.unshift(sunday);
    return weekdays;
  }

  getYearName(date: DateTime): string {
    return this.clone(date).toLocaleString({ year: 'numeric' });
  }

  getFirstDayOfWeek(): number {
    // How do we look this up from Luxon?
    return 0;
  }

  getNumDaysInMonth(date: DateTime): number {
    return date.daysInMonth;
  }

  clone(date: DateTime): DateTime {
    return date.setLocale(this.locale);
  }

  createDate(year: number, month: number, date: number): DateTime {
    const dateTime = DateTime.local(year, month + 1, date).setLocale(this.locale);

    if (!dateTime.isValid) {
      throw new Error(`${dateTime.invalidReason}: ${dateTime.invalidExplanation}`);
    }

    return dateTime;
  }

  today(): DateTime {
    return DateTime.local().setLocale(this.locale);
  }

  parse(value: any, parseFormat: any): DateTime | null {
    // If value is a number, assume it is in ms not seconds!
    if (typeof value === 'number') {
      return DateTime.fromMillis(value).setLocale(this.locale);
    }

    if (typeof value === 'object') {
      if (value instanceof Date) {
        return DateTime.fromJSDate(value).setLocale(this.locale);
      }

      if (value instanceof DateTime) {
        return this.clone(value);
      }

      return DateTime.fromObject(value).setLocale(this.locale);
    }

    if (typeof value === 'string') {
      let date: DateTime;
      date = DateTime.fromISO(value).setLocale(this.locale);
      if (!date.isValid) {
        date = DateTime.fromRFC2822(value).setLocale(this.locale);
      }
      if (!date.isValid) {
        date = DateTime.fromFormat(value, parseFormat).setLocale(this.locale);
      }
      if (!value) {
        return null;
      }

      return date;
    }

    throw new Error(`Unable to parse date value, ${value}, using format, ${parseFormat}`);
  }

  format(date: DateTime, displayFormat: any): string {
    if (!this.isValid(date)) {
      throw new Error('LuxonDateAdapter: Cannot format invalid date.');
    }

    if (typeof displayFormat === 'string') {
      return this.clone(date).toFormat(displayFormat);
    }

    if (typeof displayFormat === 'object') {
      return this.clone(date).toLocaleString(displayFormat);
    }

    throw new Error('LuxonDateAdapter: Cannot parse displayFormat');
  }

  addCalendarYears(date: DateTime, years: number): DateTime {
    return this.clone(date).plus({ years });
  }

  addCalendarMonths(date: DateTime, months: number): DateTime {
    return this.clone(date).plus({ months });
  }

  addCalendarDays(date: DateTime, days: number): DateTime {
    return this.clone(date).plus({ days });
  }

  toIso8601(date: DateTime): string {
    return date.toISO();
  }

  /**
   * Returns the given value if given a valid DateTime or null. Deserializes valid ISO 8601 strings
   * (https://www.ietf.org/rfc/rfc3339.txt) and valid Date objects into valid DateTimes and empty
   * string into null. Returns an invalid date for all other values.
   */
  deserialize(value: any): DateTime | null {
    let date: DateTime;

    if (value == null) {
      return value;
    }

    if (this.isDateInstance(value) && this.isValid(value)) {
      return this.clone(value);
    }

    if (value instanceof Date) {
      return DateTime.fromJSDate(value).setLocale(this.locale);
    }

    if (typeof value === 'number') {
      return DateTime.fromMillis(value).setLocale(this.locale);
    }

    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = DateTime.fromISO(value).setLocale(this.locale);
      if (date.isValid) {
        return date;
      } else {
        date = DateTime.fromRFC2822(value).setLocale(this.locale);
      }
      if (date.isValid) {
        return date;
      }
    }

    return DateTime.invalid('unable to deserialize date', 'deserialization for `${value}` is not implemented');
  }

  isDateInstance(obj: any): boolean {
    return DateTime.isDateTime(obj);
  }

  isValid(date: DateTime): boolean {
    return date.isValid;
  }

  invalid(): DateTime {
    return DateTime.invalid('Invalid DateTime created by LuxonDateAdapter');
  }

}
