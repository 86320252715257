<ng-container *appIfHasPrivileges="requiredPrivileges">
  <a mat-menu-item (click)="toggleExpansion()">
    <div fxLayout="row">
      <div fxFlex>{{ title }}</div>
      <div fxFlex fxFlexFill></div>
      <div fxFlex="nogrow">
        <mat-icon *ngIf="!expanded">expand_more</mat-icon>
        <mat-icon *ngIf="expanded">expand_less</mat-icon>
      </div>
    </div>
  </a>
  <mat-divider></mat-divider>
  <div *ngIf="expanded" class="sub-menu-container">
    <ng-content></ng-content>
  </div>
</ng-container>
