import { NgModule } from '@angular/core';
import { EntityDefinitionService } from '@ngrx/data';
import { entityMetadata, entityUrlMap } from '@main/settings/ngrx/entity-metadata';
import { CcoEntityUrlRegistryService } from '@app/data/cco-entity-url-registry.service';

@NgModule()
export class SettingsCoreDataModule {
  constructor(entityDefinitionService: EntityDefinitionService, entityUrlRegistry: CcoEntityUrlRegistryService) {
    entityUrlRegistry.registerEntityUrls(entityUrlMap);
    entityDefinitionService.registerMetadataMap(entityMetadata);
  }
}
