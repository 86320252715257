import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { Auth2Service } from '../auth2/services/auth2.service';
import { JwtHelperService } from '@modules/angular-jwt/jwthelper.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenSources$: { [aud: string]: BehaviorSubject<string> } = {};
  private tokens$: { [aud: string]: Observable<string> } = {};
  // this.apiAccessTokenSource$.pipe(
  //   // Never emit an expired token
  //   filter(jwt => jwt && this.getMillisecondsUntilTokenExpiry(jwt) > 30 * 1000),
  // );
  private fetchInProgress: { [aud: string]: boolean } = {};

  private jwtHelper = new JwtHelperService();

  constructor(private authService: Auth2Service) {}

  getToken$(aud: string): Observable<string> {
    const tokenSource$ = this.tokenSources$[aud] ?? new BehaviorSubject<string>(null);
    if (this.tokenSources$[aud] == null) {
      this.tokenSources$[aud] = tokenSource$;
      this.tokens$[aud] = tokenSource$.pipe(
        filter(jwt => jwt && this.getMillisecondsUntilTokenExpiry(jwt) > 30 * 1000),
      );
    }

    const currentToken = tokenSource$.getValue();
    // Refresh the token if it will expire within a minute
    if (
      this.fetchInProgress[aud] !== true &&
      (currentToken == null || this.getMillisecondsUntilTokenExpiry(currentToken) < 1 * 60 * 1000)
    ) {
      this.fetchInProgress[aud] = true;
      this.authService.getToken$(aud).pipe(
        catchError((err) => {
          tokenSource$.error(err);
          return EMPTY;
        }),
      ).subscribe((token) => {
        tokenSource$.next(token);
        this.fetchInProgress[aud] = false;
      });
    }

    return this.tokens$[aud];
  }

  private getMillisecondsUntilTokenExpiry(jwt: string): number {
    return DateTime.fromJSDate(this.jwtHelper.getTokenExpirationDate(jwt))
      .diff(DateTime.local())
      .valueOf();
  }
}
