import { inject, Injectable } from '@angular/core';
import { EntityServices } from '@ngrx/data';
import { timer } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { auth2Actions } from '@core/auth2/store/auth2.actions';
import { shareReplay, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'any'
})
export class ResolvedSettingFetcherService {
  private readonly actions$ = inject(Actions);

  constructor(private entityServices: EntityServices) {}

  start() {
    const authSuccessTrigger$ = this.actions$.pipe(
      ofType(auth2Actions.checkAuthenticationStatusSuccess),
      take(1),
      shareReplay(),
    );

    authSuccessTrigger$.pipe(
      switchMap(() => timer(0, 24 * 60 * 60 * 1000))
    ).subscribe(() => {
      this.entityServices.getEntityCollectionService('SettingType').load();
      this.entityServices.getEntityCollectionService('SettingNode').load();
    });

    authSuccessTrigger$.pipe(
      switchMap(() => timer(0, 60 * 60 * 1000))
    ).subscribe(() => this.entityServices.getEntityCollectionService('ResolvedSetting').load());
  }
}
