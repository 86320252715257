import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { SetActiveMediaQueries } from '../store/actions/media.actions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MediaQueries } from '@app/shared/utils/media-queries';

const MEDIA_QUERY_ALIAS_MAP = {
  [MediaQueries.XS]: ['xs', 'lt-sm'],
  [MediaQueries.SM]: ['sm'],
  [MediaQueries.MD]: ['md'],
  [MediaQueries.LG]: ['lg'],
  [MediaQueries.XL]: ['xl', 'gt-lg'],
  [MediaQueries.LT_MD]: ['lt-md'],
  [MediaQueries.LT_LG]: ['lt-lg'],
  [MediaQueries.LT_XL]: ['lt-xl'],
  [MediaQueries.GT_XS]: ['gt-xs'],
  [MediaQueries.GT_SM]: ['gt-sm'],
  [MediaQueries.GT_MD]: ['gt-md'],
};

@Injectable()
export class MediaStateSerializerService {
  constructor(private store: Store, private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe(Object.keys(MEDIA_QUERY_ALIAS_MAP)).pipe(
        debounceTime(100),
        distinctUntilChanged(),
        map((result) => {
          const activeAliases: string[] = [];
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query])
              activeAliases.push(...MEDIA_QUERY_ALIAS_MAP[query]);
          }
          return activeAliases;
        }),
      )
      .subscribe(activeMediaQueries =>
        this.store.dispatch(SetActiveMediaQueries({ activeMediaQueries })),
      );
  }
}
