import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { Observable } from 'rxjs';

export function sendCredentialsInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const appConfig = inject<AppConfig>(APP_CONFIG);

  if (req.url.startsWith(appConfig.apiBaseUrl)) {
    return next(req.clone({
      withCredentials: true,
    }));
  }

  return next(req);
}
