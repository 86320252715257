import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LiveBatch } from '@cco/model';
import { DefaultDataService } from '@ngrx/data';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { CcoHttpUrlGenerator } from '@app/store/cco-http-url-generator';

@Injectable()
export class LiveBatchDataService extends DefaultDataService<LiveBatch> {
  constructor(@Inject(APP_CONFIG) config: AppConfig, http: HttpClient, httpUrlGenerator: CcoHttpUrlGenerator) {
    super('LiveBatch', http, httpUrlGenerator, {
      root: config.cloudApiBaseUrl,
    });
  }
}
