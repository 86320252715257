import { Actions, createEffect, ofType } from '@ngrx/effects';
import { auth2Actions } from '@core/auth2/store/auth2.actions';
import { inject } from '@angular/core';
import { UserTenantService } from '@core/user-tenant/user-tenant.service';
import { of, switchMap } from 'rxjs';
import { userTenantApiActions } from '@core/user-tenant/user-tenant-api.actions';
import { catchError, map, tap } from 'rxjs/operators';
import { userTenantActions } from '@core/user-tenant/user-tenant.actions';

export const listUserTenants = createEffect((
  actions$ = inject(Actions),
  userTenantService = inject(UserTenantService),
) => {
  return actions$.pipe(
    ofType(auth2Actions.checkAuthenticationStatusSuccess),
    switchMap(action => userTenantService.listUserTenants$().pipe(
      map(userTenants => userTenantApiActions.listSuccess({
        homeTenantId: action.currentUser.homeTenantId,
        userTenants,
      })),
      catchError(err => of(userTenantApiActions.listFailure({ err }))),
    )),
  );
}, { functional: true });

export const saveSelectedTenantToSessionStorage = createEffect((
  actions$ = inject(Actions),
) => {
  return actions$.pipe(
    ofType(userTenantActions.selectTenant),
    tap(({ tenantId }) => {
      sessionStorage.setItem('selectedTenantId', tenantId);
      window.location.href = window.location.origin;
    }),
  );
}, { dispatch: false, functional: true });
