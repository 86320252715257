import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { httpOptions } from '@app/shared/services/standard.service';
import { NotificationMessage, NotificationTarget } from '@cco/model';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';

@Injectable()
export class NotificationService {
  protected readonly apiBaseUrl: string;

  constructor(@Inject(APP_CONFIG) config: AppConfig, protected http: HttpClient) {
    this.apiBaseUrl = config.apiBaseUrl;
  }

  sendNotification$(target: NotificationTarget, message: NotificationMessage) {
    if (target.type === 'truck') {
      return this.http.post(
        `${this.apiBaseUrl}/trucks/${target.id}/send-notification`,
        message,
        httpOptions,
      );
    } else if (target.type === 'employee') {
      return this.http.post(
        `${this.apiBaseUrl}/employees/${target.id}/send-notification`,
        message,
        httpOptions,
      );
    } else {
      throw new Error('Unhandled notification target type');
    }
  }
}
