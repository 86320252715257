<mat-menu #connectionQualityDetails class="connection-quality-panel">
  <span
    mat-menu-item
    style="font-family: Roboto, 'Helvetica Neue', sans-serif; margin-left: 1em; margin-right: 1em"
    >Connection Quality: {{ connectionQuality }}</span
  >
  <mat-divider></mat-divider>
  <!--
    <span mat-menu-item style="font-family: monospace;"><span matTooltip="Sample Time Window">Win.</span> | <span matTooltip="Mean Round Trip Time">Mean RTT</span> | Std Dev. | Drop %</span>
    <mat-divider></mat-divider>
    <span mat-menu-item style="font-family: monospace;">&nbsp;30s | {{meanRtt2 | number:'1.1-1'}} ms | N/A | N/A</span>
    <span mat-menu-item style="font-family: monospace;">&nbsp;75s | {{meanRtt5 | number:'1.1-1'}} ms | {{stddev5 | number:'1.1-1'}} ms | {{dropPercentage5 | number:'1.1-1'}}%</span>
    <span mat-menu-item style="font-family: monospace;">375s | {{meanRtt | number:'1.1-1'}} ms | {{stddev | number:'1.1-1'}} ms | {{dropPercentage | number:'1.1-1'}}%</span>
    -->
  <mat-table [dataSource]="connectionQualityDataSource">
    <ng-container matColumnDef="window">
      <mat-header-cell *matHeaderCellDef matTooltip="Sample Window (seconds)"
        >Win. (s)</mat-header-cell
      >
      <mat-cell *matCellDef="let row" style="text-align: right">{{ row.window }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="meanRtt">
      <mat-header-cell *matHeaderCellDef matTooltip="Mean Round Trip Time (ms)"
        >Mean RTT</mat-header-cell
      >
      <mat-cell *matCellDef="let row" style="text-align: right"
        >{{ row.meanRtt | number: '1.1-1' }}&nbsp;ms</mat-cell
      >
    </ng-container>
    <ng-container matColumnDef="stdDev">
      <mat-header-cell *matHeaderCellDef matTooltip="Standard Deviation (ms)"
        >Std. Dev.</mat-header-cell
      >
      <mat-cell *matCellDef="let row" style="text-align: right">
        <ng-container *ngIf="row.stdDev; else noStdDev"
          >{{ row.stdDev | number: '1.1-1' }}&nbsp;ms</ng-container
        >
        <ng-template #noStdDev>N/A</ng-template>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="dropRate">
      <mat-header-cell *matHeaderCellDef>Drop Rate</mat-header-cell>
      <mat-cell *matCellDef="let row" style="text-align: right"
        >{{ row.dropRate * 100.0 | number: '1.0-0' }}%</mat-cell
      >
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns"></mat-row>
  </mat-table>
  <button
    *ngIf="connectionQuality === ConnectionQuality.NO_CONNECTION"
    mat-raised-button
    (click)="reconnect()"
    style="margin-left: 1em"
  >
    Manual Reconnect
  </button>
  <!-- <button mat-raised-button (click)="debug()">Debug</button> -->
</mat-menu>
<button
  mat-menu-item
  [matMenuTriggerFor]="connectionQualityDetails"
  matTooltip="Connection Quality: {{ connectionQuality }}"
>
  <!--
  <div style="background: url(assets/icons/baseline-signal_cellular_connected_no_internet_0_bar-24px.svg) no-repeat -9999px -9999px;"></div>
  <img *ngIf="connectionQuality === ConnectionQuality.NO_CONNECTION" src="assets/icons/baseline-signal_cellular_connected_no_internet_0_bar-24px.svg" alt="[No Connection]" />
  <img *ngIf="connectionQuality === ConnectionQuality.TERRIBLE" src="assets/icons/baseline-signal_cellular_0_bar-24px.svg" alt="[Terrible Connection]" />
  <img *ngIf="connectionQuality === ConnectionQuality.BAD" src="assets/icons/baseline-signal_cellular_1_bar-24px.svg" alt="[Bad Connection]" />
  <img *ngIf="connectionQuality === ConnectionQuality.MEDIOCRE" src="assets/icons/baseline-signal_cellular_2_bar-24px.svg" alt="[Mediocre Connection]" />
  <img *ngIf="connectionQuality === ConnectionQuality.GOOD" src="assets/icons/baseline-signal_cellular_3_bar-24px.svg" alt="[Good Connection]" />
  <img *ngIf="connectionQuality === ConnectionQuality.EXCELLENT" src="assets/icons/baseline-signal_cellular_4_bar-24px.svg" alt="[Excellent Connection]" />
  Connection Quality
  -->
  <!--
    <mat-icon *ngIf="connectionQuality === ConnectionQuality.NO_CONNECTION">signal_wifi_0_bar</mat-icon>
    <mat-icon *ngIf="connectionQuality === ConnectionQuality.TERRIBLE">signal_wifi_1_bar</mat-icon>
    <mat-icon *ngIf="connectionQuality === ConnectionQuality.BAD">signal_wifi_2_bar</mat-icon>
    <mat-icon *ngIf="connectionQuality === ConnectionQuality.GOOD">signal_wifi_3_bar</mat-icon>
    <mat-icon *ngIf="connectionQuality === ConnectionQuality.EXCELLENT">signal_wifi_4_bar</mat-icon>
  -->
  <mat-icon>network_check</mat-icon>Connection Quality
  <!--
    {{connectionQuality}}
    -->
</button>
