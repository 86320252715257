import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { JwtHelperService } from './jwthelper.service';
import { JWT_OPTIONS } from './jwtoptions.token';

import { filter, mergeMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JwtConfig, JwtInterceptorRule } from '@modules/angular-jwt/angular-jwt.module';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  headerName: string;
  authScheme: string | ((request?: HttpRequest<any>) => string);
  rules: JwtInterceptorRule[];
  throwNoTokenError: boolean;
  skipWhenExpired: boolean;

  constructor(
    @Inject(JWT_OPTIONS) config: JwtConfig,
    public jwtHelper: JwtHelperService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.headerName = config.headerName || 'Authorization';
    this.authScheme =
      config.authScheme || config.authScheme === ''
        ? config.authScheme
        : 'Bearer ';

    this.rules = config.rules ?? [];
    this.throwNoTokenError = config.throwNoTokenError || false;
    this.skipWhenExpired = config.skipWhenExpired;
  }

  firstMatchingRule(request: HttpRequest<any>): JwtInterceptorRule | null{
    return this.rules.find(r => request.url.startsWith(r.baseUrl));
  }

  handleInterception(
    token: string | null,
    request: HttpRequest<any>,
    next: HttpHandler,
  ) {
    const authScheme = this.jwtHelper.getAuthScheme(this.authScheme, request);
    let tokenIsExpired = false;

    if (!token && this.throwNoTokenError) {
      throw new Error('Could not get token from tokenGetter function.');
    }

    if (this.skipWhenExpired) {
      tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;
    }

    if (token && tokenIsExpired && this.skipWhenExpired) {
      request = request.clone();
    } else if (token) {
      request = request.clone({
        setHeaders: {
          [this.headerName]: `${authScheme}${token}`,
        },
      });
    }
    return next.handle(request);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const rule = this.firstMatchingRule(request);
    if (rule != null) {
      return rule.getToken$(rule.aud).pipe(
        filter(token => token != null),
        take(1),
        mergeMap((token: string | null) => {
          return this.handleInterception(token, request, next);
        })
      );
    }
    return next.handle(request);
  }
}
