import { Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CgccStompService } from '@core/services/cgcc-stomp.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { Log } from '@core/store/actions/logger.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectCurrentUser } from '@core/auth2/store/auth.selectors';

export enum ConnectionQuality {
  NO_CONNECTION = 'No Connection',
  EXCELLENT = 'Excellent',
  GOOD = 'Good',
  MEDIOCRE = 'Mediocre',
  BAD = 'Bad',
  TERRIBLE = 'Terrible',
  GATHERING_DATA = 'Gathering Data',
}

export const CONNECTION_MONITOR_INITIAL_INTERVAL = 15000;

export class ConnectionStatistics {
  window: number;
  meanRtt: number;
  stdDev: number;
  dropRate: number;
}

@UntilDestroy()
@Injectable({
  providedIn: 'any',
})
export class ConnectionMonitorService {
  lastRtt$: Subject<number> = new Subject<number>();
  rtts: number[] = [];
  connectionQuality$ = new BehaviorSubject<ConnectionQuality>(ConnectionQuality.GATHERING_DATA);
  private hasConnection = true;

  private stompSubscription: Subscription;

  connectionQualityDataSource$ = new BehaviorSubject<ConnectionStatistics[]>(null);


  constructor(
    private store: Store,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private stompService: CgccStompService,
  ) {
    this.store.select(selectCurrentUser).pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
    ).subscribe((currentUser) => {
      if (this.stompSubscription) {
        this.stompSubscription.unsubscribe();
      }

      if (currentUser == null) {
        return;
      } else {
        this.stompSubscription = this.stompService
          .watch(`/user/topic/connection-monitor`)
          .pipe(untilDestroyed(this))
          .subscribe((message) => {
            const now = DateTime.local();
            const then = DateTime.fromMillis(parseInt(message.body, 10));
            // console.log('then', then.toISOString(), 'now', now.toISOString());
            // console.log('/user message', now.diff(then));
            this.lastRtt$.next(now.diff(then).valueOf());
          });
      }
    });
    // Since rxjs/interval waits 1 interval before emitting
    // this.store.dispatch(new XsrfPing());
    interval(CONNECTION_MONITOR_INITIAL_INTERVAL)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        // console.log('interval (' + i + ')');
        // this.store.dispatch(new XsrfPing());
        if (
          this.stompService.publishWithoutLocalQueuing(
            '/app/connection-monitor',
            `${DateTime.local().valueOf()}`,
          ) === false
        ) {
          this.lastRtt$.next(-1);
        }
      });
    this.lastRtt$.pipe(untilDestroyed(this)).subscribe((lastRtt) => {
      this.rtts = [lastRtt, ...this.rtts.slice(0, 24)];

      if (lastRtt === -1) {
        console.warn('ping timeout or dropped');
        // this.authService.xsrfPing().subscribe();
      }

      const droppedCount = this.rtts.filter(rtt => rtt === -1).length;
      const dropRate = droppedCount / this.rtts.length;
      // const dropPercentage = (droppedCount / this.rtts.length) * 100.0;
      const droppedCount5 = this.rtts.slice(0, 5).filter(rtt => rtt === -1).length;
      const dropRate5 = droppedCount5 / this.rtts.slice(0, 5).length;
      const dropPercentage5 = dropRate5 * 100.0;
      const filteredRtts = this.rtts.filter(rtt => rtt !== -1);
      const filteredRtt2s = filteredRtts.slice(0, 2);
      const filteredRtt5s = filteredRtts.slice(0, 5);
      const meanRtt = filteredRtts.reduce((sum, rtt) => sum + rtt, 0.0) / filteredRtts.length;
      const variation = filteredRtts.reduce((v, rtt) => v + Math.pow(rtt - meanRtt, 2), 0);
      const stddev = Math.sqrt(filteredRtts.length < 2 ? 0 : variation / (filteredRtts.length - 1));

      const meanRtt2 = filteredRtt2s.reduce((sum, rtt) => sum + rtt, 0.0) / filteredRtt2s.length;
      const meanRtt5 = filteredRtt5s.reduce((sum, rtt) => sum + rtt, 0.0) / filteredRtt5s.length;
      const variation5 = filteredRtt5s.reduce((v, rtt) => v + Math.pow(rtt - meanRtt5, 2), 0);
      const stddev5 = Math.sqrt(
        filteredRtt5s.length < 2 ? 0 : variation5 / (filteredRtt5s.length - 1),
      );
      this.connectionQualityDataSource$.next([
        { window: 30, meanRtt: meanRtt2, stdDev: null, dropRate: null },
        { window: 75, meanRtt: meanRtt5, stdDev: stddev5, dropRate: dropRate5 },
        { window: 375, meanRtt, stdDev: stddev, dropRate },
      ]);

      this.ngZone.run(() => {
        if (this.rtts[0] === -1) {
          // If the last ping timed out
          if (this.hasConnection === true) {
            this.connectionQuality$.next(ConnectionQuality.NO_CONNECTION);
            this.snackBar.open('Connection Lost: Operating in Disconnected Mode', 'Close', {
              duration: 5000,
            });
            this.store.dispatch(
              Log({
                level: 5,
                source: 'ConnectionMonitorComponent',
                message: 'Connection to Server Lost: Operating in Disconnected Mode',
              }),
            );
            this.hasConnection = false;
          }
        } else {
          if (this.hasConnection === false) {
            this.snackBar.open('Connection Restored', 'Close', { duration: 2500 });
            this.store.dispatch(
              Log({
                level: 6,
                source: 'ConnectionMonitorComponent',
                message: 'Connection to Server Restored',
              }),
            );
            this.hasConnection = true;
          }

          if (meanRtt2 < 60 && dropPercentage5 === 0) {
            this.connectionQuality$.next(ConnectionQuality.EXCELLENT);
          } else if (meanRtt2 < 150 && dropPercentage5 === 0) {
            this.connectionQuality$.next(ConnectionQuality.GOOD);
          } else if (meanRtt2 < 300 && dropPercentage5 < 1) {
            this.connectionQuality$.next(ConnectionQuality.MEDIOCRE);
          } else if (meanRtt2 < 600 && dropPercentage5 < 2) {
            this.connectionQuality$.next(ConnectionQuality.BAD);
          } else {
            this.connectionQuality$.next(ConnectionQuality.TERRIBLE);
          }
        }
      });
    });
  }

  reconnect() {
    this.stompService.activate();
  }
}
