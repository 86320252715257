import * as Sentry from '@sentry/angular';
import { AppConfig } from '@cco/apps/cco-frontend';

/**
 * See https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
 */
export const HTTP_STATUS_TEXT: { [statusCode: number]: string } = {
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  209: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I\'m a teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Content',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export function upsertExceptionTypeValue(event: Sentry.Event, value?: string, type?: string): void {
  const exception = (event.exception = event.exception || {});
  const values = (exception.values = exception.values || []);
  const firstException = (values[0] = values[0] || {});
  firstException.value = value || '';
  firstException.type = type || 'Error';
}

export function stripQueryParams(url: string): string {
  const newUrl = new URL(url);
  newUrl.search = '';
  return newUrl.toString();
}

export function makeUrlGeneric(config: AppConfig, url: string): string {
  const apiBaseUrl = new URL(config.apiBaseUrl, window.location.origin).toString();

  return url
    .replace(apiBaseUrl, '<apiBaseUrl>')
    .replace(config.cloudApiBaseUrl, '<cloudApiBaseUrl>')
    .replace(config.mailerBaseUrl, '<mailerBaseUrl>')
    .replace(config.pdfExporterBaseUrl, '<pdfExporterBaseUrl>')
    .replace(config.zitadelBaseUrl, '<zitadelBaseUrl>')
    .replace(config.externalAccountingSyncBaseUrl, '<externalAccountingSyncBaseUrl>')
    .replace(/\/o\/[A-Za-z0-9_-]+\//, '/o/<tenantId>/')
    .replace(/\/[0-9]+($|\/)/g, '/<int>$1')
    .replace(/\/[0-9a-f-]{36}($|\/)/g, '/<uuid>$1');
}
