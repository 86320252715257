import {
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
} from '@angular/core';
import { EntityDefinitionService, EntityMetadataMap } from '@ngrx/data';
import { CcoEntityUrlRegistryService, EntityUrlMap } from '@app/data/cco-entity-url-registry.service';

const ENTITIES_PROVIDER = new InjectionToken('@cco/data/entities-provider Feature Entities Provider');

export function provideEntities(entityMetadataMap: EntityMetadataMap): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ENTITIES_PROVIDER,
      deps: [EntityDefinitionService],
      useFactory: (eds: EntityDefinitionService) => {
        eds.registerMetadataMap(entityMetadataMap);

        return 'REGISTERED';
      },
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useFactory: () => {
        return () => inject(ENTITIES_PROVIDER);
      },
    },
  ]);
}

const ENTITY_URL_PROVIDER = new InjectionToken('@cco/data/entities Feature Entities URL Provider');

export function provideEntityUrls(entityUrlMap: EntityUrlMap): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ENTITY_URL_PROVIDER,
      deps: [CcoEntityUrlRegistryService],
      useFactory: (entityUrlRegistry: CcoEntityUrlRegistryService) => {
        entityUrlRegistry.registerEntityUrls(entityUrlMap);

        return 'REGISTERED';
      },
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useFactory: () => {
        return () => inject(ENTITY_URL_PROVIDER);
      },
    },
  ]);
}
