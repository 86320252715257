import { Component, Input } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { FlexModule } from '@angular/flex-layout/flex';
import { RouterLink } from '@angular/router';
import { MatMenuItem } from '@angular/material/menu';
import { IfHasPrivilegesDirective } from '../../../modules/auth-utils/directives/if-has-privileges.directive';

@Component({
    selector: 'app-side-nav-menu-item',
    template: `
<ng-container *appIfHasPrivileges="requiredPrivileges">
  <a mat-menu-item [routerLink]="routerLink">
    <div fxLayout="row">
      <div fxFlex><ng-content></ng-content></div>
      <div fxFlex fxFlexFill></div>
      <!--
      <div fxFlex="nogrow"><mat-icon>chevron_right</mat-icon></div>
      -->
    </div>
  </a>
  <mat-divider></mat-divider>
</ng-container>
`,
    styleUrls: ['./side-nav-menu-item.component.scss'],
    standalone: true,
    imports: [IfHasPrivilegesDirective, MatMenuItem, RouterLink, FlexModule, MatDivider]
})
export class SideNavMenuItemComponent {
  @Input()
  routerLink: any[] | string | null | undefined;
  @Input()
  requiredPrivileges: string[];
}
