import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { isCognitoIdentityTokenExpiredException } from '@core/services/cognito-identity.service';

export function httpErrorInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse && err.message.startsWith('Http failure during parsing for')) {
        Sentry.addBreadcrumb({
          level: 'info',
          message: 'HttpErrorResponse Object Details',
          data: {
            errorConstructorName: err.constructor?.name,
            errorErrorKeys: (err.error != null && typeof err.error === 'object') ? Object.keys(err.error) : [],
            errorErrorErrorType: err.error.error != null ? Object.prototype.toString.call(err.error.error) : null,
            errorErrorErrorKeys: (err.error.error != null && typeof err.error.error === 'object') ? Object.keys(err.error.error) : [],
            errorErrorTextType: err.error.text != null ? Object.prototype.toString.call(err.error.text) : null,
            errorErrorTextStart: (err.error.text != null && typeof err.error.text === 'string') ? err.error.text.slice(0, 100) : null,
            errorErrorTextEnd: (err?.error?.text != null && typeof err.error.text === 'string') ? err.error.text.slice(Math.max(0, err.error.text.length - 100), err.error.text.length) : null,
            errorKeys: Object.keys(err),
            errorName: err.name,
            headerKeys: err.headers instanceof HttpHeaders ? (err.headers as HttpHeaders).keys() : [],
          },
        });
      }

      if (shouldCaptureHttpErrorResponse(err)) {
        const contexts: Record<string, Record<string, unknown>> = {};

        if (err instanceof HttpErrorResponse) {
          if (shouldCaptureHttpErrorResponseHeaders(err)) {
            contexts[ 'httpResponseHeaders' ] = {};
            const headers: HttpHeaders = err.headers;
            for (const headerName of headers.keys()) {
              const headerValues = headers.getAll(headerName);
              contexts.httpResponseHeaders[ headerName ] = headerValues.length === 1 ? headerValues[0] : headerValues;
            }
          }

          if (err.status === 0 && err.error instanceof XMLHttpRequest) {
            contexts['xmlHttpRequest'] = {};
            contexts.xmlHttpRequest['readyState'] = err.error.readyState;
          }
        }

        Sentry.captureException(err, {
          fingerprint: [
            'http-error.interceptor',
          ],
          contexts: {
            ...contexts,
          },
          level: getSentryErrorLevelForHttpErrorResponse(err),
        });
      }

      throw err;
    }),
  );
}

function shouldCaptureHttpErrorResponse(err: unknown): boolean {
  if (!(err instanceof HttpErrorResponse))
    return true;

  if ([404, 409].includes(err.status))
    return false;

  if (isCognitoIdentityTokenExpiredException(err))
    return false;

  return true;
}

function shouldCaptureHttpErrorResponseHeaders(err: HttpErrorResponse): boolean {
  return [503, 504].includes(err.status);
}

export function getSentryErrorLevelForHttpErrorResponse(err: unknown): Sentry.SeverityLevel | undefined {
  if (!(err instanceof HttpErrorResponse))
    return;

  if ([403, 503, 504].includes(err.status))
    return 'info';

  if ([400, 422].includes(err.status))
    return 'warning';
}
