import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '@app/core/containers/layout/layout.component';
import { AppRoutes } from '@app/shared/utils/route-utils';
import { PrivilegeGuard } from '@core/auth/privilege-guard.service';
import { provideState } from '@ngrx/store';
import * as fromCustomerFilter from '@main/customers/store/customer-filter.reducer';
import { MixerPlcEffects } from '@main/trucks/store/mixer-plc.effects';
import { provideEffects } from '@ngrx/effects';
import { MixerPlcService } from '@main/trucks/services/mixer-plc.service';
import * as fromBatchReportFilter from '@main/batch-reports/store/batch-report-filter.reducer';
import * as fromContactFilter from '@main/contacts/store/contact-filter.reducer';
import * as fromAssignmentFilter from '@main/assignments/store/assignment-filter.reducer';
import { AssignmentEffects } from '@main/assignments/store/assignment.effects';
import * as fromDeliverySlipFilter from '@main/delivery-slips/store/delivery-slip-filter.reducer';
import * as fromInvoiceFilter from '@main/invoices/store/invoice-filter.reducer';
import * as fromJobFilter from '@main/jobs/store/job-filter.reducer';
import * as fromProjectFilter from '@main/projects/store/project-filter.reducer';
import { NotificationEffects } from '@modules/notification/store/notification.effects';
import { NotificationService } from '@modules/notification/services/notification.service';
import { provideEntities, provideEntityUrls } from '@app/data/provide-entities';
import { notificationEntityMetadataMap } from '@modules/notification/store/entity-metadata';
import { IotThingsStore } from '@modules/iot-things/store';
import { IotThingEffects } from '@modules/iot-things/store/iot-thing.effects';
import { settingsEntityMetadata, settingsEntityUrlMap } from '@main/settings/ngrx/edit-entity-metadata';
import * as iotDataEffects from '@core/iot-data/iot-data.effects';
import { iotDataFeature } from '@core/iot-data/iot-data.reducer';
import { AmplifyCredentialsProviderService } from '@core/services/amplify-credentials-provider.service';
import { CognitoIdentityService } from '@core/services/cognito-identity.service';
import { stateViewerFeature } from '@modules/state-viewer/state-viewer.reducer';
import * as fromMyProfile from '@modules/my-profile/store/reducers';
import { customerStatisticsFeature } from '@main/customers/store/customer-statistics.reducer';
import * as customerStatisticsEffects from '@main/customers/store/customer-statistics.effects';

// export const schedulerModule = '';

const routes: AppRoutes = [
  {
    path: '',
    redirectTo: '/app',
    pathMatch: 'full',
  },
  {
    path: 'app',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/app/trucks',
        pathMatch: 'full',
      },
      {
        path: 'accounting-units',
        loadChildren: () => import('@app/main/accounting-units/accounting-units.routes')
          .then(m => m.ACCOUNTING_UNITS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_BILLABLE_ITEMS' ] },
      },
      {
        path: 'assignments',
        loadChildren: () =>
          import('@app/main/assignments/assignments.routes').then(m => m.ASSIGNMENTS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_ASSIGNMENTS', 'UI_VIEW_APP_ASSIGNMENTS' ] },
        providers: [
          provideState(fromAssignmentFilter.featureKey, fromAssignmentFilter.reducer),
          provideEffects(AssignmentEffects),
        ],
      },
      {
        path: 'assignment-targets',
        loadChildren: () =>
          import('@app/main/assignment-targets/assignment-targets.routes').then(
            m => m.ASSIGNMENT_TARGETS_ROUTES,
          ),
        canLoad: [ PrivilegeGuard ],
        data: { requiredPrivileges: [ 'READ_ASSIGNMENT_TARGETS', 'UI_VIEW_APP_ASSIGNMENT_TARGETS' ] },
      },
      {
        path: 'auth2',
        loadChildren: () => import('@core/auth2/auth2.module').then(m => m.Auth2Module),
      },
      {
        path: 'batch-records',
        loadChildren: () =>
          import('@app/main/batch-records/batch-records.routes').then(m => m.BATCH_RECORDS_ROUTES),
        canLoad: [ PrivilegeGuard ],
        data: { requiredPrivileges: [ 'READ_BATCH_REPORTS', 'UI_VIEW_APP_BATCH_REPORTS' ] },
      },
      {
        path: 'batch-reports',
        loadChildren: () =>
          import('@main/batch-reports/batch-reports.routes').then(m => m.BATCH_REPORT_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_BATCH_REPORTS', 'UI_VIEW_APP_BATCH_REPORTS' ] },
        providers: [
          provideState(fromBatchReportFilter.featureKey, fromBatchReportFilter.reducer),
        ],
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('@app/main/contacts/contacts.routes').then(m => m.CONTACTS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_CONTACTS', 'UI_VIEW_APP_CONTACTS' ] },
        providers: [
          provideState(fromContactFilter.contactFilterFeatureKey, fromContactFilter.reducer),
        ],
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('@app/main/customers/customers.routes').then(m => m.CUSTOMERS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: {
          requiredPrivileges: [ 'READ_CUSTOMERS', 'UI_VIEW_APP_CUSTOMERS' ],
        },
        providers: [
          provideState(fromCustomerFilter.featureKey, fromCustomerFilter.reducer),
          provideState(customerStatisticsFeature),
          provideEffects(customerStatisticsEffects),
        ],
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@main/dashboard/dashboard.routes').then(m => m.DASHBOARD_ROUTES),
        canLoad: [ PrivilegeGuard ],
        data: {
          requiredPrivileges: [ 'UI_VIEW_MENU_DASHBOARDS' ],
        },
      },
      {
        path: 'delivery-slips',
        loadChildren: () =>
          import('@app/main/delivery-slips/delivery-slips.routes').then(m => m.DELIVERY_SLIPS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_DELIVERY_SLIPS', 'UI_VIEW_APP_DELIVERY_SLIPS' ] },
        providers: [
          provideState(fromDeliverySlipFilter.featureKey, fromDeliverySlipFilter.reducer),
        ],
      },
      {
        path: 'billable-items',
        loadChildren: () =>
          import('@app/main/billable-items/billable-items.routes').then(m => m.BILLABLE_ITEMS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_BILLABLE_ITEMS', 'UI_VIEW_APP_BILLABLE_ITEMS' ] },
      },
      {
        path: 'employees',
        loadChildren: () =>
          import('@app/main/employees/employees.routes').then(m => m.EMPLOYEES_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_EMPLOYEES', 'UI_VIEW_APP_EMPLOYEES' ] },
        providers: [
          provideEntities(notificationEntityMetadataMap),
        ],
      },
      {
        path: 'global-admin',
        loadChildren: () =>
          import('../../app/global-admin/global-admin.module').then(m => m.GlobalAdminModule),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_TENANTS' ] },
      },
      {
        path: 'iot-gateways',
        loadChildren: () =>
          import('@main/iot-gateways/iot-gateways.routes').then(m => m.IOT_GATEWAYS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: {
          requiredPrivileges: [ 'READ_AWS_IOT_THINGS', 'UI_VIEW_APP_IOT_GATEWAYS' ],
        },
      },
      {
        path: 'jobs',
        loadChildren: () => import('@app/main/jobs/jobs.routes').then(m => m.JOBS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_JOBS', 'UI_VIEW_APP_JOBS' ] },
        providers: [
          provideState(fromJobFilter.featureKey, fromJobFilter.reducer),
        ],
      },
      {
        path: 'mix-designs',
        loadChildren: () =>
          import('@app/main/mix-designs/mix-designs.routes').then(m => m.MIX_DESIGNS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_MIX_DESIGNS', 'UI_VIEW_APP_MIX_DESIGNS' ] },
      },
      {
        path: 'my-company',
        loadChildren: () =>
          import('@app/main/my-company/my-company.routes').then(m => m.MY_COMPANY_ROUTES),
        canLoad: [PrivilegeGuard],
        providers: [
          provideEntities(settingsEntityMetadata),
          provideEntityUrls(settingsEntityUrlMap),
        ],
        data: {
          requiredPrivileges: [ 'READ_COMPANY', 'UI_VIEW_APP_COMPANY' ],
        },
      },
      {
        path: 'my-profile',
        loadChildren: () =>
          import('@app/modules/my-profile/my-profile.routes').then(m => m.MY_PROFILE_ROUTES),
        providers: [
          provideState('myProfile', fromMyProfile.reducers),
        ],
      },
      {
        path: 'price-levels',
        loadChildren: () =>
          import('@app/main/price-levels/price-levels.routes').then(m => m.PRICE_LEVELS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_PRICE_LEVELS', 'UI_VIEW_APP_PRICE_LEVELS' ] },
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('@app/main/projects/projects.routes').then(m => m.PROJECTS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_PROJECTS', 'UI_VIEW_APP_PROJECTS' ] },
        providers: [
          provideState(fromProjectFilter.projectFilterFeatureKey, fromProjectFilter.reducer),
        ],
      },
      {
        path: 'assignment-scheduler',
        loadChildren: () =>
          import('@app/main/assignment-scheduler/assignment-scheduler.routes').then(
            m => m.ASSIGNMENT_SCHEDULER_ROUTES,
          ),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_ASSIGNMENTS', 'UI_VIEW_APP_ASSIGNMENT_SCHEDULER' ] },
      },
      {
        path: 'job-scheduler',
        loadChildren: () =>
          import('@app/main/job-scheduler/job-scheduler.routes').then(m => m.JOB_SCHEDULER_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_JOBS', 'UI_VIEW_APP_JOB_SCHEDULER' ] },
      },
      {
        path: 'tax-groups',
        loadChildren: () =>
          import('@app/main/tax-groups/tax-groups.routes').then(m => m.TAX_GROUPS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_TAXES', 'UI_VIEW_APP_TAXES' ] },
      },
      {
        path: 'invoice-terms',
        loadChildren: () =>
          import('@app/main/invoice-terms/invoice-terms.routes').then(m => m.INVOICE_TERMS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_INVOICE_TERMS', 'UI_VIEW_APP_INVOICE_TERMS' ] },
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('@app/main/invoices/invoices.routes').then(m => m.INVOICES_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_INVOICES', 'UI_VIEW_APP_INVOICES' ] },
        providers: [
          provideState(fromInvoiceFilter.featureKey, fromInvoiceFilter.reducer),
        ],
      },
      {
        path: 'taxes',
        loadChildren: () => import('@app/main/taxes/taxes.routes').then(m => m.TAXES_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_TAXES', 'UI_VIEW_APP_TAXES' ] },
      },
      {
        path: 'tags',
        loadChildren: () => import('@app/main/tags/tags.routes').then(m => m.TAGS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_TAGS', 'UI_VIEW_APP_TAGS' ] },
      },
      {
        path: 'trucks',
        loadChildren: () => import('@app/main/trucks/trucks.routes').then(m => m.TRUCKS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_TRUCKS', 'UI_VIEW_APP_TRUCKS' ] },
        providers: [
          provideEffects(MixerPlcEffects),
          MixerPlcService,
          provideEntities(notificationEntityMetadataMap),
          NotificationService,
          provideEffects(NotificationEffects),
          provideState(IotThingsStore.featureKey, IotThingsStore.reducers),
          provideEffects(IotThingEffects),
        ],
      },
      {
        path: 'user',
        loadChildren: () => import('@app/main/user/user.routes').then(m => m.USER_ROUTES),
        providers: [
          provideEntities(settingsEntityMetadata),
          provideEntityUrls(settingsEntityUrlMap),
        ],
      },
      {
        path: 'users',
        loadChildren: () => import('@app/main/users/users.routes').then(m => m.USERS_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_USERS', 'UI_VIEW_APP_USERS' ] },
      },
      {
        path: 'roles',
        loadChildren: () => import('@app/main/roles/roles.routes').then(m => m.ROLES_ROUTES),
        canLoad: [PrivilegeGuard],
        data: { requiredPrivileges: [ 'READ_ROLES', 'UI_VIEW_APP_USER_ROLES' ] },
      },
      {
        path: 'accounting/sage300',
        loadChildren: () => import('@app/modules/accounting/sage300/sage300.module').then(m => m.Sage300Module),
      },
      {
        path: 'accounting/quickbooks-online',
        loadChildren: () => import('@app/modules/accounting/quickbooks-online/quickbooks-online.module').then(m => m.QuickbooksOnlineModule),
      },
      {
        path: 'accounting/sage50Us',
        loadChildren: () => import('@app/modules/accounting/sage50Us/sage50Us.module').then(m => m.Sage50UsModule),
      },
    ],
    providers: [
      provideState(iotDataFeature),
      provideState(stateViewerFeature),
      provideEffects(iotDataEffects),
      AmplifyCredentialsProviderService,
      CognitoIdentityService,
    ],
  },
  {
    path: '**',
    loadChildren: () => import('@modules/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
