import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Integration } from '@sentry/types';
import { APP_CONFIG, AppConfig, setConfig } from '@cco/apps/cco-frontend';
import { jwtOptionsFactory } from '@app/app.module';
import { environment } from '@env/environment';
import { AppComponent } from '@app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { Auth2Service } from '@core/auth2/services/auth2.service';
import { entityConfig } from '@app/store/entity-metadata';
import { RouterEffects } from '@app/core/store/effects/router.effects';
import { TruckLiveDataEffects } from '@app/main/trucks/store/truck-live-data.effects';
import { MediaEffects } from '@app/core/store/effects/media.effects';
import { Auth2Effects } from '@core/auth2/store/auth2.effects';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { NgrxStoreIdbModule } from '@core/ngrx/ngrx-store-idb';
import { provideState, provideStore } from '@ngrx/store';
import { AppRoutingModule } from '@app/app-routing.module';
import { CoreModule } from '@app/core';
import { E2eModule } from '@app/e2e/e2e.module';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { getReducers, metaReducers, REDUCER_TOKEN } from '@app/store/reducers';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CustomRouterStateSerializer } from '@app/core/router';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { JWT_OPTIONS, JwtModule } from '@app/modules/angular-jwt/angular-jwt.module';
import { TokenService } from '@core/services/token.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CcoEntityCacheDataService } from '@app/data/cco-entity-cache-data.service';
import { CcoDefaultDataServiceFactory } from '@app/data/cco-default-data-service';
import { CcoEntityCollectionReducerMethodsFactory } from '@app/data/ngrx-data';
import { CcoHttpUrlGenerator } from '@app/store/cco-http-url-generator';
import { CcoPersistenceResultHandler } from '@app/data/cco-persistence-result-handler';
import {
  DefaultDataServiceFactory,
  EntityCacheDataService,
  EntityCollectionReducerMethodsFactory,
  EntityDataModule,
  PersistenceResultHandler,
} from '@ngrx/data';
import { MyErrorHandler } from '@app/shared/utils/my-error-handler';
import { userTenantFeature } from '@core/user-tenant/user-tenant.reducer';
import * as userTenantEffects from '@core/user-tenant/user-tenant.effects';
import { ngErrorExtractor, WrappingSentryErrorHandler } from '@app/shared/utils/wrapping-sentry-error-handler';
import {
  HTTP_STATUS_TEXT,
  makeUrlGeneric,
  stripQueryParams,
  upsertExceptionTypeValue
} from '@app/shared/utils/sentry.utils';

interface HasCcoConfigFile {
  ccoConfigFile: string;
}

fetch((window as unknown as HasCcoConfigFile).ccoConfigFile)
  .then(response => response.json() as Promise<AppConfig>)
  .then((config) => {
    const errorHandlerFactory = () => {
      if (config.sentry.enabled === true) {
        return new WrappingSentryErrorHandler({
          extractor: ngErrorExtractor,
          showDialog: config.sentry.showReportDialog,
        });
      } else {
        return new MyErrorHandler();
      }
    };

    setConfig(config);

    if (window.location.origin === 'https://dev.concretecloudops.io')
      window.location.href = 'https://app.concretecloudops.io';

    // Sentry.io
    if (config.sentry.enabled == null || config.sentry.enabled === true) {
      const integrations: Integration[] = [
        Sentry.breadcrumbsIntegration({
          console: false,
        }),
        Sentry.extraErrorDataIntegration({
          // Normalize depth needs to be at least 1 higher
          // See https://github.com/getsentry/sentry-javascript/issues/2539
          depth: 4,
        }),
      ];
      if (config.sentry.replay.enabled)
        integrations.push(Sentry.replayIntegration());
      if (config.sentry.tracing.enabled)
        integrations.push(Sentry.browserTracingIntegration());

      Sentry.init({
        beforeSend: (event, hint) => {
          const error = hint.originalException;

          if (error instanceof HttpErrorResponse) {
            const genericUrl = makeUrlGeneric(config, stripQueryParams(error.url));

            const type = (error.status === 400 && typeof error.error?.error === 'string')
              ? error.error.error
              : 'HttpErrorResponse';
            const reasonPhrase = (error?.error?.error && typeof error.error.error === 'string')
              ? ` ${error.error.error}`
              : error.statusText
                ? ` ${error.statusText}`
                : HTTP_STATUS_TEXT[error.status] ?? '';
            const value = (error.error?.error && error.error?.message)
              ? `HTTP ${error.status}${reasonPhrase}: ${genericUrl}: ${error.error.message}`
              : `HTTP ${error.status}${reasonPhrase}: ${genericUrl}`;
            upsertExceptionTypeValue(event, value, type);
            delete event.exception.values[0].stacktrace;
            if (event.fingerprint == null)
              event.fingerprint = [];
            event.fingerprint.push('HttpErrorResponse');
            event.fingerprint.push(genericUrl);
            event.fingerprint.push(`http-status-${error.status}`);
          }

          return event;
        },
        dsn: config.sentry.dsn,
        environment: config.sentry.environment,
        integrations,
        normalizeDepth: 5,
        release: config.sentry.release,
        replaysOnErrorSampleRate: config.sentry.replay.errorSampleRate ?? 1.0,
        sendDefaultPii: true,
        tracePropagationTargets: [
          /^\//,
          config.apiBaseUrl,
          config.cloudApiBaseUrl,
          config.mailerBaseUrl,
          config.pdfExporterBaseUrl,
        ],
        tracesSampleRate: config.sentry.tracing.sampleRate ?? (environment.production ? 0.02 : 1.0),
        transport: Sentry.makeBrowserOfflineTransport(),
        tunnel: config.sentry.tunnel,
      });
    }

    if (environment.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, {
      providers: [
        {
          provide: APP_CONFIG,
          useValue: config,
        },
        provideStore(REDUCER_TOKEN, {
          metaReducers,
          runtimeChecks: {
            strictActionImmutability: false,
            strictStateImmutability: false,
          },
        }),
        provideState(userTenantFeature),
        provideEffects(userTenantEffects),
        ...environment.providers,
        importProvidersFrom(
          BrowserModule,
          E2eModule,
          CoreModule,
          AppRoutingModule,
          NgrxStoreIdbModule.forRoot({
            idb: {
              dbName: 'cco',
              storeName: 'ngrx',
            },
            rehydrate: true,
            keys: [ 'auth2', 'entityCache', 'iotThings', 'selects', 'settings', 'auth', 'logger', 'globalFilters' ],
            debugInfo: false,
          }), StoreRouterConnectingModule.forRoot({
            stateKey: 'router',
          }),
          EffectsModule.forRoot([ Auth2Effects, MediaEffects, TruckLiveDataEffects, RouterEffects ]), EntityDataModule.forRoot(entityConfig), JwtModule.forRoot({
            jwtOptionsProvider: {
              provide: JWT_OPTIONS,
              useFactory: jwtOptionsFactory,
              deps: [ TokenService, Auth2Service ],
            },
          }), FlexLayoutModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })),
        {
          provide: PersistenceResultHandler,
          useClass: CcoPersistenceResultHandler,
        },
        // { provide: HttpUrlGenerator, useClass: CcoHttpUrlGenerator },
        CcoHttpUrlGenerator,
        {
          provide: EntityCollectionReducerMethodsFactory,
          useClass: CcoEntityCollectionReducerMethodsFactory,
        },
        {
          provide: DefaultDataServiceFactory,
          useClass: CcoDefaultDataServiceFactory,
        },
        {
          provide: EntityCacheDataService,
          useClass: CcoEntityCacheDataService,
        },
        {
          provide: ErrorHandler,
          useFactory: errorHandlerFactory,
        },
        { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
        // This is to disable tooltips on mobile devices in order to allow scrolling
        // within elements with tooltips.
        // @see https://github.com/angular/components/issues/4892#issuecomment-649108017
        {
          provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {
            touchGestures: 'off',
          },
        },
        {
          provide: REDUCER_TOKEN,
          useFactory: getReducers,
        },
        {
          provide: Sentry.TraceService,
          deps: [ Router ],
        },
        {
          provide: APP_INITIALIZER,
          // This is just used to initialize the TraceService, so we don't need
          // to do anything.
          // @see https://docs.sentry.io/platforms/javascript/guides/angular/
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          useFactory: () => () => {
          },
          deps: [ Sentry.TraceService ],
          multi: true,
        },
      ],
    })
      .catch((err) => {
        console.error('An unhandled error occurred during platform bootstrapping', err);
        Sentry.captureException(err);
      });
  })
  .catch(err => console.error('An unhandled error occurred in main()', err));
