<h2 mat-dialog-title>{{ config.appName }}</h2>
<mat-dialog-content style="padding-bottom: 0.5em">
  <p>Copyright &copy; {{ thisYear }}. All rights reserved.</p>
  <h4>Frontend</h4>
  <ul>
    <li>URI: {{ location.origin }}</li>
    <li (click)="showCommitIds = !showCommitIds">
      Version: {{ config.version.version }}
      <span *ngIf="showCommitIds">({{ config.version.commit }})</span>
    </li>
  </ul>
  <div *ngIf="updateAvailable$ | async; else noUpdateAvailable" fxLayout="column" fxLayoutGap="1rem">
    <div fxFlex>A newer version of the frontend is available.</div>
    <div fxFlex="nogrow"><button *ngIf="!updating" mat-raised-button (click)="update()">Update and Reload Page</button></div>
  </div>
  <ng-template #noUpdateAvailable>
    <div>
      <button *ngIf="!checkingForUpdates" mat-raised-button (click)="checkForUpdates()">Check for Updates</button>
      <div *ngIf="checkingForUpdates" fxLayout="row" fxLayoutGap="1rem">
        <div fxFlex="nogrow"><mat-spinner [diameter]="24"></mat-spinner></div>
        <div fxFlex>Checking for updates ...</div>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="aboutBackend$ | async; let backend">
    <h4>Backend</h4>
    <ul>
      <li *ngIf="backend.hostname">
        Hostname: {{ backend.hostname }}
      </li>
      <li *ngIf="backend.instance.instanceId">
        InstanceId: {{ backend.instance.instanceId }} ({{ backend.instance.availabilityZone }})
      </li>
      <li>
        Version: {{ backend.versionInfo.version }}
        <span *ngIf="showCommitIds">({{ backend.versionInfo.commit }})</span>
      </li>
    </ul>
  </ng-container>
  <h3>Third-party Licenses</h3>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let license of licenses">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ license.productName }}
        </mat-panel-title>
        <mat-panel-description>{{ license.licenseName }}</mat-panel-description>
      </mat-expansion-panel-header>
      <pre>{{ license.licenseText }}</pre>
    </mat-expansion-panel>
  </mat-accordion>
  <h3>Diagnostics</h3>
  <div class="flex flex-row gap-2">
    <button mat-raised-button (click)="onClearLocalStorage()">Clear Local Data</button>
    <button
      *ngIf="serviceWorkerController"
      mat-raised-button
      (click)="unregisterServiceWorkers()">
      Unregister Service Worker
    </button>
    <button *appIfHasPrivileges="['UI_VIEW_TOOLS_STATE_VIEWER']" mat-raised-button (click)="showStateViewerDialog()">
      <mat-icon fontSet="material-symbols-outlined">account_tree</mat-icon>
      State Viewer
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="true">Close</button>
</mat-dialog-actions>
