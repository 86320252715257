import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatIconModule],
})
export class CustomIconsModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'dp_assignment',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/DP_assignment_icon.svg'),
    );

    iconRegistry.addSvgIcon(
      'scheduler_today',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/calendar-today4.svg'),
    );

    iconRegistry.addSvgIcon(
      'scheduler_one_day',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/calendar-day5.svg'),
    );

    iconRegistry.addSvgIcon(
      'scheduler_one_week',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/calendar-week5.svg'),
    );

    iconRegistry.addSvgIcon(
      'scheduler_one_month',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/calendar-month1.svg'),
    );

    iconRegistry.addSvgIcon(
      'calendar_empty',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/calendar-empty.svg'),
    );

    iconRegistry.addSvgIcon(
      'mixer_front',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mixer/mixer-front.svg'),
    );
    // lower detail mixer icons
    iconRegistry.addSvgIcon(
      'mixer-left-less-detail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mixer-left4.svg'),
    );
    iconRegistry.addSvgIcon(
      'mixer-right-less-detail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mixer-right3.svg'),
    );
    // higher detail mixer icons
    iconRegistry.addSvgIcon(
      'mixer-left-more-detail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mixer-left2.svg'),
    );
    iconRegistry.addSvgIcon(
      'mixer-right-more-detail',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mixer-right2.svg'),
    );
    // front mixer icon
    iconRegistry.addSvgIcon(
      'mixer-front',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mixer-front.svg'),
    );
    // quickbooks logo icon
    iconRegistry.addSvgIcon(
      'qb-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/quickbooks2.svg'),
    );
    // Google sync icons
    // iconRegistry.addSvgIcon(
    //   'google-synced-yes',
    //   sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/google-check.svg'),
    // );
    iconRegistry.addSvgIcon(
      'google-synced-no',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/google-ex.svg'),
    );
    iconRegistry.addSvgIcon(
      'google-sync',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/google-sync.svg'),
    );
    iconRegistry.addSvgIcon(
      'google-linked',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/google-linked.svg'),
    );
    iconRegistry.addSvgIcon(
      'google-unlinked',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scheduler/google-unlinked.svg'),
    );
  }
}
