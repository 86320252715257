import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';

@Injectable()
export class AuthService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) {}

  logout(): Observable<any> {
    return this.http.post<any>(`${this.config.apiBaseUrl}/logout`, {});
  }
}
