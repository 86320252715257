import { MatDateFormats } from '@angular/material/core';


export const MAT_LUXON_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'M/d/yyyy',
  },
  display: {
    dateInput: 'M/d/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'DD',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};
