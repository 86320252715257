import { Component, inject, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LuxonModule } from '@modules/luxon';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButton, MatButtonModule, MatIconButton } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { selectGlobalFiltersState } from '@app/store/reducers';
import { setGlobalFilters } from '@app/store/global-filter.actions';
import { Interval } from '@cco/model';
import { DateTime } from 'luxon';
import { MatCardModule } from '@angular/material/card';
import { LuxonDateRangePipe } from '@modules/luxon/pipes/luxon-date-range.pipe';
import {
  ConnectionMonitorService,
  ConnectionQuality
} from '@modules/connection-monitor/services/connection-monitor.service';
import { Dictionary } from '@ngrx/entity';
import { stateViewerFeature } from '@modules/state-viewer/state-viewer.reducer';
import { AsyncPipe } from '@angular/common';
import { StateViewerDialogComponent } from '@modules/state-viewer/state-viewer-dialog.component';
import { IfHasPrivilegesDirective } from '@modules/auth-utils/directives/if-has-privileges.directive';

interface Status {
  color: string;
  iconName: string;
}

@UntilDestroy()
@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  standalone: true,
  imports: [FlexLayoutModule, LuxonModule, MatIconModule, MatDialogModule, MatCardModule, LuxonDateRangePipe, MatButton, AsyncPipe, MatIconButton, IfHasPrivilegesDirective],
})
export class StatusBarComponent implements OnInit {
  private readonly dialog = inject(MatDialog);
  private readonly store = inject(Store);
  protected readonly stateViewerPreviouslyOpened$ = this.store.select(stateViewerFeature.selectOpened);

  form: FormGroup;
  statusMap: Dictionary<Status> = {
    'No Connection': { color: '#f44336', iconName: 'wifi_bad' },
    'Gathering Data': { color: '#3f51b5', iconName: 'network_check'},
    Excellent: { color: '#3f51b5', iconName: 'wifi'},
    Good: { color: '#3f51b5', iconName: 'wifi'},
    Mediocre: { color: '#3f51b5', iconName: 'wifi_2_bar'},
    Bad: { color: '#3f51b5', iconName: 'wifi_1_bar'},
    Terrible: { color: '#3f51b5', iconName: 'wifi_1_bar'},
  };
  connectionQuality: ConnectionQuality = ConnectionQuality.GATHERING_DATA;
  shouldFadeIn = false;

  constructor(protected fb: FormBuilder, protected connectionMonitorService: ConnectionMonitorService) {
    this.form = fb.group({
      start: null,
      end: null,
    });

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(formValue =>
        this.store.dispatch(setGlobalFilters({
          startAtOrAfter: formValue.start,
          endAtOrBefore: formValue.end,
        })),
      );
  }

  ngOnInit() {
    this.store.select(selectGlobalFiltersState).pipe(

      untilDestroyed(this),
    ).subscribe((globalFilters) => {
        if (globalFilters.startAtOrAfter !== this.form.value.start || globalFilters.endAtOrBefore !== this.form.value.end) {
          this.form.setValue({start: globalFilters.startAtOrAfter, end: globalFilters.endAtOrBefore}, {emitEvent: false});
        }
      });

    this.connectionMonitorService.connectionQuality$
      .pipe(untilDestroyed(this))
      .subscribe((connectionQuality) => {
        this.connectionQuality = connectionQuality;
        this.shouldFadeIn = true;
        setTimeout(() => {
          this.shouldFadeIn = false;
        }, 500);
      });
  }

  onDateRangeClick() {
    this.dialog.open(DateRangeSelectorDialogComponent, {data: {formValue: this.form.value}})
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((range: {start: DateTime, end: DateTime}) => {
        if (range != null) {
          this.form.setValue({
            start: typeof range.start === 'string' ?
              range.start : range.start?.startOf('day')?.toString() ?? null,
            end: typeof range.end === 'string' ? range.end : range.end?.endOf('day')?.toString() ?? null,
          });
        }
      });
  }

  protected showStateViewerDialog() {
    this.dialog.open<StateViewerDialogComponent>(StateViewerDialogComponent, {
      minWidth: '85vw',
      maxHeight: '100vh',
    });
  }
}


@Component({
  selector: 'app-date-range-selector',
  template: `
    <mat-dialog-content>
      <mat-form-field style="width: 100%">
        <mat-label>Global Date Range Filter</mat-label>
        <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <button mat-icon-button matSuffix class="cco-clear-global-date-range-filter" (click)="form.setValue({start: null, end: null})">
          <mat-icon>clear</mat-icon>
        </button>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-raised-button [mat-dialog-close]="previousInterval"><mat-icon>clear</mat-icon>Cancel</button>
      <button mat-raised-button [mat-dialog-close]="{start: resetValue, end: null}"><mat-icon>restart_alt</mat-icon>Reset to Default</button>
      <button mat-raised-button class="cco-global-date-range-filter-confirm-btn" [mat-dialog-close]="form.value" color="primary"><mat-icon>done</mat-icon>Confirm</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
  ]
})
export class DateRangeSelectorDialogComponent {
  previousInterval: Interval;
  form: FormGroup;
  resetValue: DateTime;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { formValue: Interval}, protected fb: FormBuilder) {
    this.previousInterval = data.formValue;
    this.form = fb.group({
      start: this.data.formValue.start,
      end: this.data.formValue.end,
    });
    this.resetValue = DateTime.local().minus({ month: 3 });
  }
}
