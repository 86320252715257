import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'truncateWithEllipses',
    standalone: true
})
export class TruncateWithEllipsesPipe implements PipeTransform {
  transform(value: string, maxLength: number): string {
    if (!maxLength) {
      throw new Error('maxLength must be defined');
    }

    if (!value) {
      return value;
    }

    if (value.length > maxLength) {
      return value.slice(0, maxLength).concat('…');
    }

    return value;
  }
}
