import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DefaultDataService } from '@ngrx/data';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { CcoHttpUrlGenerator } from '@app/store/cco-http-url-generator';
import { GoogleCalendar } from '@modules/link-google-api/services/select-calendar-api.service';

@Injectable({
  providedIn: 'any'
})
export class GoogleCalendarDataService extends DefaultDataService<GoogleCalendar> {
  constructor(@Inject(APP_CONFIG) config: AppConfig, http: HttpClient, httpUrlGenerator: CcoHttpUrlGenerator) {
    super('GoogleCalendar', http, httpUrlGenerator, {
      root: config.cloudApiBaseUrl,
    });
  }
}
