import { EntityMetadataMap } from '@ngrx/data';
import { EntityUrlMap } from '@app/data/cco-entity-url-registry.service';
import { SettingValue } from '@cco/model';

export const entityMetadata: EntityMetadataMap = {
  ResolvedSetting: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
    selectId: (settingValue: SettingValue) => settingValue.settingId,
  },
  SettingNode: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
  },
  SettingType: {
    additionalCollectionState: {
      loadedAt: null,
      queryLoadedAt: {},
    },
    entityDispatcherOptions: {
      optimisticDelete: false,
    },
  }
};

export const entityUrlMap: EntityUrlMap = {
  ResolvedSetting: {
    entityResourceUrl: 'settings/resolved',
    collectionResourceUrl: 'settings/resolved',
  },
  SettingNode: {
    entityResourceUrl: 'settings',
    collectionResourceUrl: 'settings',
  },
  SettingType: {
    entityResourceUrl: 'settings/types',
    collectionResourceUrl: 'settings/types',
  }
};
