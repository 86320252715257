import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { exhaustMap, merge, of, switchMap } from 'rxjs';
import { Auth2Service } from '@core/auth2/services/auth2.service';
import { MatDialog } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import {
  ErrorDialogComponent,
  ErrorDialogData,
} from '@modules/error-dialog/components/error-dialog/error-dialog.component';
import { catchError, map, tap } from 'rxjs/operators';
import {
  amplifyCredentialProviderActions,
  backendIotDataApiActions,
  cognitoIdentityApiActions,
  stsApiActions
} from '@core/iot-data/iot-data.actions';
import {
  CognitoIdentityService,
  isCognitoIdentityTokenExpiredException
} from '@core/services/cognito-identity.service';
import { Store } from '@ngrx/store';
import { iotDataFeature } from '@core/iot-data/iot-data.reducer';
import { notNullish } from '@app/shared/rxjs/operators/standard-operators';
import { GetCallerIdentityCommand, STSClient } from '@aws-sdk/client-sts';
import { concatLatestFrom } from '@ngrx/operators';

export const getIotConnectionInfo = createEffect((
  actions$ = inject(Actions),
  authService = inject(Auth2Service),
) => {
  return actions$.pipe(
    ofType(amplifyCredentialProviderActions.userOrTenantChanged),
    switchMap(() => authService.getIotConnectionInfo$().pipe(
      map(iotConnectionInfo => backendIotDataApiActions.getIotConnectionInfoSuccess({ iotConnectionInfo })),
      catchError(err => of(backendIotDataApiActions.getIotConnectionInfoFailure({ err }))),
    )),
  );
}, { functional: true });

export const getFederationToken = createEffect((
  actions$ = inject(Actions),
  authService = inject(Auth2Service),
) => {
  return actions$.pipe(
    ofType(cognitoIdentityApiActions.federationTokenExpired),
    switchMap(() => authService.getCognitoFederationToken$().pipe(
      map(idToken => backendIotDataApiActions.getFederationTokenSuccess({ idToken })),
      catchError(err => of(backendIotDataApiActions.getFederationTokenFailure( { err }))),
    )),
  );
}, { functional: true });

export const getId = createEffect((
  store = inject(Store),
  identityService = inject(CognitoIdentityService),
) => {
  return store.select(iotDataFeature.selectGetIdRequest).pipe(
    notNullish(),
    switchMap(request => identityService.getId$(request).pipe(
      map(response => cognitoIdentityApiActions.getIdSuccess({
        identityId: response.IdentityId,
      })),
      catchError((err) => {
        if (isCognitoIdentityTokenExpiredException(err))
          return of(cognitoIdentityApiActions.federationTokenExpired());

        return of(cognitoIdentityApiActions.getIdFailure( { err }));
      }),
    )),
  );
}, { functional: true });

/**
 * Fetch updated credentials whenever the required inputs change,
 * an event is fired that the credentials are about to expire,
 * or an event is fired that a forced credential refresh has been
 * requested.
 */
export const getCredentialsForIdentity = createEffect((
  actions$ = inject(Actions),
  store = inject(Store),
  identityService = inject(CognitoIdentityService),
) => {
  return merge(
    actions$.pipe(
      ofType(
        amplifyCredentialProviderActions.credentialsExpiring,
        amplifyCredentialProviderActions.forceCredentialsRefreshRequested,
      ),
      exhaustMap(() => store.select(iotDataFeature.selectGetCredentialsForIdentityRequest)),
    ),
    store.select(iotDataFeature.selectGetCredentialsForIdentityRequest),
  ).pipe(
    notNullish(),
    switchMap(request => identityService.getCredentialsForIdentity$(request).pipe(
      map(response => cognitoIdentityApiActions.getCredentialsForIdentitySuccess({ response })),
      catchError((err) => {
        if (isCognitoIdentityTokenExpiredException(err))
          return of(cognitoIdentityApiActions.federationTokenExpired());

        return of(cognitoIdentityApiActions.getCredentialsForIdentityFailure( { err }));
      }),
    )),
  );
}, { functional: true });

/**
 * When AWS credentials for IoT-Data access are updated get the caller identity for troubleshooting.
 * The userId is logged in CloudWatch AWSIotLogsV2 messages in the principalId field.
 */
export const getCallerIdentity = createEffect((
  actions$ = inject(Actions),
  store = inject(Store),
) => {
  return actions$.pipe(
    ofType(cognitoIdentityApiActions.getCredentialsForIdentitySuccess),
    concatLatestFrom(() => store.select(iotDataFeature.selectAwsCredentials)),
    switchMap(async ([,awsCreds]) => {
      const client = new STSClient({
        region: awsCreds.identityId.split(':')[0],
        credentials: awsCreds.credentials,
      });

      return await client.send(new GetCallerIdentityCommand({}));
    }),
    map(resp => stsApiActions.getCallerIdentitySuccess({
      callerIdentity: {
        account: resp.Account,
        arn: resp.Arn,
        userId: resp.UserId,
      },
    })),
    catchError(err => of(stsApiActions.getCallerIdentityFailure({ err }))),
  );
}, { functional: true });

export const backendIotDataApiActionFailures = createEffect((
  actions$ = inject(Actions),
  dialog = inject(MatDialog),
) => {
  return actions$.pipe(
    ofType(
      backendIotDataApiActions.getIotConnectionInfoFailure,
      backendIotDataApiActions.getFederationTokenFailure,
      cognitoIdentityApiActions.getIdFailure,
      cognitoIdentityApiActions.getCredentialsForIdentityFailure,
      stsApiActions.getCallerIdentityFailure,
    ),
    tap((action: { err: any, type: string }) => {
      const eventId = Sentry.captureException(action.err, {
        fingerprint: [
          '{{ default }}',
          'iot-data.effects',
          action.type,
        ],
      });
      dialog.open<ErrorDialogComponent, ErrorDialogData>(ErrorDialogComponent, {
        data: {
          title: 'System Error',
          message: 'An error occurred when attempting to refresh your credentials for accessing real-time data',
          eventId,
        },
      });
    }),
  );
}, { functional: true, dispatch: false });
