import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Log } from '@app/core/store/actions/logger.actions';
import { Go } from '@app/core/store/actions/router.actions';
import * as fromRoot from '@app/store/reducers';
import { Auth2Service } from '@core/auth2/services/auth2.service';
import { auth2Actions } from '@core/auth2/store/auth2.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Auth2States } from './auth2.reducer';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { HttpClient } from '@angular/common/http';
import {
  ErrorDialogComponent,
  ErrorDialogData,
} from '@modules/error-dialog/components/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import { rehydrateAction, rehydrateErrorAction } from '@core/ngrx/ngrx-store-idb/ngrx-store-idb.actions';
import { userProfileActions } from '@main/user/user-profile.actions';
import { concatLatestFrom } from '@ngrx/operators';

function redirectUrl() {
  const redirectPath = location.href.replace(location.origin, '');
  if (redirectPath.startsWith('/app/auth2'))
    return null;
  return location.href;
}

@Injectable()
export class Auth2Effects {
  constructor(
    @Inject(APP_CONFIG) private readonly config: AppConfig,
    private actions$: Actions,
    private auth2Service: Auth2Service,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private http: HttpClient,
    private dialog: MatDialog,
  ) {}

  checkAuthenticationStatus$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(
        rehydrateAction,
        rehydrateErrorAction,
        userProfileActions.refreshTokensSuccess
      ),
      switchMap(() =>
        this.auth2Service.checkAuthenticationStatus().pipe(
          map(user => auth2Actions.checkAuthenticationStatusSuccess({
            currentUser: user,
            redirectUrl: redirectUrl(),
          })),
          catchError((error) => {
            // 504 - the backend is down (gateway error)
            // 500 - the backend is shutting down (database connection error)
            if (error && error.status && (error.status === 504 || error.status === 500)) {
              return of<Action>(
                auth2Actions.checkAuthenticationStatusTimeout({
                  redirectUrl: redirectUrl(),
                }),
              );
            } else {
              return of(
                auth2Actions.checkAuthenticationStatusFailure({ error }),
                Log({
                  level: 5,
                  source: `${ this.constructor.name }.checkAuthenticationStatus$`,
                  message: `You are not currently logged in`,
                  object: error,
                }),
              );
            }
          }),
        ),
      ),
    ); },
  );

  checkAuthenticationStatusSuccess$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(auth2Actions.checkAuthenticationStatusSuccess),
      concatLatestFrom(() => this.store.select((fromRoot.selectAuth2StatePreviousState))),
      switchMap(([action, authState]) => {
        if (authState === Auth2States.AUTHENTICATING) {
          if (action.redirectUrl) {
            const redirectPath = action.redirectUrl.replace(location.origin, '');
            return of(Go({ path: [redirectPath] }));
          }

          return of(Go({ path: ['/app'] }));
        }

        if (action.redirectUrl) {
          if (location.href !== action.redirectUrl) {
            // For this to really work, we either need to call router.natigateByUrl or fully parse the URL
            const redirectPath = action.redirectUrl.replace(location.origin, '');
            // const splitRedirectPath = redirectPath.split(';');
            // const routeParams = {};
            // if (splitRedirectPath.slice(1).length > 0) {
            //   for (const split of splitRedirectPath.slice(1)) {
            //     const splitSplit = split.split('=');
            //     routeParams[splitSplit[0]] = splitSplit[1];
            //   }
            // }
            // return of(Go({ path: [splitRedirectPath[0], routeParams] }));
            this.router.navigateByUrl(redirectPath);
            return of(auth2Actions.authNullAction());
          }
        }

        return of(auth2Actions.authNullAction());
      }),
    ); },
  );

  checkAuthenticationStatusFailure$: Observable<Action> = createEffect(() => {
      return this.actions$.pipe(
        ofType(auth2Actions.checkAuthenticationStatusFailure),
        switchMap(() => of(auth2Actions.authenticateUser())),
      );
    },
  );

  listCurrentUserRoles$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(auth2Actions.checkAuthenticationStatusSuccess),
      switchMap(() => this.auth2Service.listCurrentUserRoles$().pipe(
        map(roles => auth2Actions.listCurrentUserRolesSuccess({ roles })),
        catchError(err => of(auth2Actions.listCurrentUserRolesFailure({ err }))),
      )),
    ); },
  );

  listCurrentUserRolesFailure$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(auth2Actions.listCurrentUserRolesFailure),
      switchMap((err) => {
        const eventId = Sentry.captureException(err, {
          fingerprint: [
            'auth2.effects',
            'listCurrentUserRolesFailure$',
          ],
        });

        this.dialog.open<ErrorDialogComponent, ErrorDialogData>(ErrorDialogComponent, {
          data: {
            title: 'System Error',
            message: 'An error occurred while attempting to fetch the roles your user has been assigned in this tenant.',
            eventId,
          },
        });

        return EMPTY;
      })
    ); },
    { dispatch: false },
  );

  getCurrentTenantUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(auth2Actions.checkAuthenticationStatusSuccess),
      switchMap(() => this.auth2Service.getCurrentTenantUser$().pipe(
        map(tenantUser => auth2Actions.getCurrentTenantUserSuccess({ tenantUser })),
        catchError(err => of(auth2Actions.getCurrentTenantUserFailure({ err }))),
      )),
    );
  });

  getCurrentTenantUserFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(auth2Actions.getCurrentTenantUserFailure),
      switchMap((err) => {
        const eventId = Sentry.captureException(err, {
          fingerprint: [
            'auth2.effects',
            'getCurrentTenantUserFailure$',
          ],
        });

        this.dialog.open<ErrorDialogComponent, ErrorDialogData>(ErrorDialogComponent, {
          data: {
            title: 'System Error',
            message: 'An error occurred while attempting to your Tenant User details.',
            eventId,
          },
        });

        return EMPTY;
      })
    );
  },
    { dispatch: false },
  );

  authenticateUser$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(auth2Actions.authenticateUser),
      switchMap(() => {
        if (
          this.route.snapshot.queryParams.authMethod &&
          this.route.snapshot.queryParams.authMethod === 'local'
        ) {
          return of(Go({ path: ['/app/auth2/login'] }));
        } else {
          return of(Go({ path: ['/app/auth2/authenticate'] }));
        }
      }),
    ); },
  );

  logout$ = createEffect(() =>
    { return this.actions$.pipe(
      ofType(auth2Actions.logout),
      switchMap(() => {
        localStorage.clear();

        return of(auth2Actions.remoteLogout());
      }),
    ); },
  );

  remoteLogout$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(auth2Actions.remoteLogout),
        switchMap(() => {
          location.href = `${this.config.apiBaseUrl}/security/logout`;
          return EMPTY;
        }),
      ); },
    { dispatch: false },
  );

  beginOAuth2LoginProcess$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(auth2Actions.beginOAuth2LoginProcess),
        concatLatestFrom(() => this.store.select((fromRoot.selectAuth2RedirectUrl))),
        switchMap(([action, redirectUrl]) => {
          return this.http.get(`${this.config.apiBaseUrl}/security/oauth2/tenant/${action.tenantCode}`).pipe(
            map(() => ([action, redirectUrl] as [{ tenantCode: string, rememberMe: boolean }, string])),
            catchError((err) => {
              if (err.status === 404) {
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    title: 'Invalid Tenant Code',
                    message: `'${action.tenantCode}' is not a valid tenant code.`
                  }
                });
              } else {
                Sentry.captureException(err);
              }
              return EMPTY;
            }),
          );
        }),
        switchMap(([action, redirectUrl]) => {
          let href = `${this.config.apiBaseUrl}/oauth2/authorization/${action.tenantCode}?rememberMe=${action.rememberMe}`;
          if (redirectUrl) {
            href = `${href}&redirect_uri=${encodeURIComponent(redirectUrl)}`;
          }

          location.href = href;

          return EMPTY;
        }),
      ); },
    { dispatch: false },
  );
}
