import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  selectSelectedNode,
  selectSelectedNodeEntriesLength,
  selectSelectedNodePagedEntries,
  selectSelectedNodePageIndex,
  selectSelectedNodePath,
  selectSelectedNodeViewType,
  stateViewerActions
} from '@modules/state-viewer/state-viewer.reducer';
import { Store } from '@ngrx/store';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDivider } from '@angular/material/divider';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { MatInput } from '@angular/material/input';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-state-viewer-dialog',
  templateUrl: './state-viewer-dialog.component.html',
  styleUrls: ['./state-viewer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatIconButton,
    MatIcon,
    AsyncPipe,
    MatCardModule,
    MatDivider,
    MatSlider,
    MatSliderThumb,
    MatInput,
  ],
})
export class StateViewerDialogComponent {
  private readonly store = inject(Store);

  protected readonly selectedNode$ = this.store.select(selectSelectedNode);
  protected readonly selectedNodeEntries$ = this.store.select(selectSelectedNodePagedEntries);
  protected readonly selectedNodeType$ = this.store.select(selectSelectedNodeViewType);
  protected readonly selectedNodePageIndex$ = this.store.select(selectSelectedNodePageIndex);
  protected readonly selectedNodeEntriesLength$ = this.store.select(selectSelectedNodeEntriesLength);
  protected readonly selectedNodePath$ = this.store.select(selectSelectedNodePath);

  constructor() {
    this.store.dispatch(stateViewerActions.opened());
  }


  protected selectStateRoot() {
    this.store.dispatch(stateViewerActions.top());
  }

  protected selectStateNodeParent() {
    this.store.dispatch(stateViewerActions.up());
  }

  protected selectStateNodeChild(pathSegment: string) {
    this.selectedNode$.pipe(
      take(1),
    ).subscribe((node) => {
      if (Object.prototype.toString.call(node) === '[object Object]' && pathSegment in node)
        this.store.dispatch(stateViewerActions.selectChild({ pathSegment }));
    });
  }

  protected selectStateNodeSlice(lastIndex: number) {
    this.store.dispatch(stateViewerActions.selectPathSlice({ lastIndex }));
  }

  protected pageSliderDisplayFn(value: number) {
    return `${value + 1}..${value + 101}`;
  }

  protected selectPage(pageIndex: number) {
    this.store.dispatch(stateViewerActions.pageSelected({ pageIndex }));
  }
}
