import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StandardService } from '@app/shared/services/standard.service';
import { Employee, TelephoneNumber } from '@cco/model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';

@Injectable()
export class EmployeeService extends StandardService<Employee, number> {
  constructor(@Inject(APP_CONFIG) config: AppConfig, protected http: HttpClient) {
    super(http, config.apiBaseUrl, `/employees`);
  }

  sendSms(
    employee: number | Employee,
    telephoneNumber: number | TelephoneNumber,
    message: string,
  ): Observable<string> {
    const employeeId = typeof employee === 'number' ? employee : employee.id;
    const telephoneNumberId =
      typeof telephoneNumber === 'number' ? telephoneNumber : telephoneNumber.id;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
    };

    const postBody = new HttpParams().set('message', message);

    return this.tenantBaseUrl$.pipe(
      switchMap(baseUrl => this.http
      .post<string>(
        `${baseUrl + this.relativeUrl}/${employeeId}/telephone-numbers/${telephoneNumberId}/sms`,
        postBody.toString(),
        httpOptions,
      )
      .pipe(map(result => result))));
  }
}
