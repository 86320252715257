import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StandardService } from '@app/shared/services/standard.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AboutBackend } from '../models/about-backend';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';

@Injectable({
  providedIn: 'root',
})
export class AboutService extends StandardService<AboutBackend, number> {
  constructor(@Inject(APP_CONFIG) config: AppConfig, protected http: HttpClient) {
    super(http, config.apiBaseUrl, `/about`);
  }

  public getAboutBackend(): Observable<AboutBackend> {
    return this.http
      .get<AboutBackend>(this.baseUrl + this.relativeUrl)
      .pipe(map(response => this.mapModelObject.bind(this)(response)));
  }
}
