import { Component, Input } from '@angular/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { FlexModule } from '@angular/flex-layout/flex';
import { MatMenuItem } from '@angular/material/menu';
import { IfHasPrivilegesDirective } from '../../../modules/auth-utils/directives/if-has-privileges.directive';

@Component({
    selector: 'app-side-nav-expandable-menu',
    templateUrl: 'side-nav-expandable-menu.component.html',
    styleUrls: ['./side-nav-expandable-menu.component.scss'],
    standalone: true,
    imports: [
        IfHasPrivilegesDirective,
        MatMenuItem,
        FlexModule,
        NgIf,
        MatIcon,
        MatDivider,
    ],
})
export class SideNavExpandableMenuComponent {
  @Input()
  title: string;
  @Input()
  expanded = false;
  @Input()
  requiredPrivileges: string[];

  toggleExpansion() {
    this.expanded = !this.expanded;
  }
}
