import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouterStateUrl } from '@app/core/router';
import { Back, Forward, Go } from '@app/core/store/actions/router.actions';
import { SelectActions } from '@app/store';
import { EntityActionFactory, EntityOp } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

const idEntityNameMap: {
  [id: string]: string;
} = {
  assignmentId: 'Assignment',
  assignmentTargetId: 'AssignmentTarget',
  batchReportId: 'BatchReport',
  billableItemAssociationId: 'BillableItemAssociation',
  contactId: 'Contact',
  customerId: 'Customer',
  deliverySlipId: 'DeliverySlip',
  billableItemId: 'BillableItem',
  employeeId: 'Employee',
  iotGatewayId: 'IotGateway',
  invoiceTermId: 'InvoiceTerm',
  invoiceId: 'Invoice',
  jobId: 'Job',
  mixDesignId: 'MixDesign',
  mixDesignIngredientId: 'MixDesignIngredient',
  priceLevelId: 'PriceLevel',
  projectId: 'Project',
  settingNodeId: 'SettingNode',
  taxId: 'Tax',
  tagId: 'Tag',
  taxGroupId: 'TaxGroup',
  truckId: 'Truck',
  unitId: 'Unit',
  userId: 'User',
};

@Injectable()
export class RouterEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location,
    private entityActionFactory: EntityActionFactory,
  ) {}

  navigate$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(Go),
        tap(({ path, query: queryParams, extras }) => {
          if (queryParams) {
            this.router.navigate(path, { queryParams, ...extras });
          } else {
            this.router.navigate(path, extras);
          }
        }),
      ); },
    { dispatch: false },
  );

  navigateBack$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(Back),
        tap(() => this.location.back()),
      ); },
    { dispatch: false },
  );

  navigateForward$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType(Forward),
        tap(() => this.location.forward()),
      ); },
    { dispatch: false },
  );

  navigated$ = createEffect(
    () =>
      { return this.actions$.pipe(
        ofType<RouterNavigationAction<RouterStateUrl>>(ROUTER_NAVIGATED),
        concatMap((action) => {
          const routerState: RouterStateUrl = action.payload.routerState;
          const actions: Action[] = [];

          // Clear selections on navigation to routes with clearSelectionKey set in data when the selectionKey is not present in params of any route
          if (routerState && routerState.routeData && routerState.routeData.length > 0) {
            const clearSelectionKey =
              routerState.routeData[routerState.routeData.length - 1].clearSelectionKey;
            if (clearSelectionKey && !routerState.flatParams[clearSelectionKey]) {
              actions.push(
                SelectActions.selectEntity({
                  entityName: idEntityNameMap[clearSelectionKey],
                  id: null,
                }),
              );
            }
          }

          Object.getOwnPropertyNames(routerState.flatParams).forEach((paramName) => {
            const idString = routerState.flatParams[paramName];
            const id = isNaN(+idString) ? idString : +idString;
            if (idEntityNameMap[paramName]) {
              actions.push(
                SelectActions.selectEntity({ entityName: idEntityNameMap[paramName], id }),
              );
            } else if (paramName !== 'q' && paramName !== 'tab' && paramName !== 'editing') {
              console.warn(`idEntityNameMap['%s'] is not defined`, paramName);
            }

            if (id !== 'new') {
              if (idEntityNameMap[paramName]) {
                actions.push(
                  this.entityActionFactory.create(
                    idEntityNameMap[paramName],
                    EntityOp.QUERY_BY_KEY,
                    id,
                  ),
                );
              }
            }
          });

          if (actions.length > 0) {
            return actions;
          }

          return EMPTY;
        }),
      ); },
    { dispatch: true },
  );
}
