import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';
import { CcoHttpUrlGenerator } from '@app/store/cco-http-url-generator';
import { TenantEmailIdentityAuthorization } from '@cco/mailer';
import { CcoDefaultDataService } from '@app/data/cco-default-data-service';

@Injectable({
  providedIn: 'any',
})
export class VerifiedEmailIdentityService extends CcoDefaultDataService<TenantEmailIdentityAuthorization> {
  constructor(@Inject(APP_CONFIG) config: AppConfig, http: HttpClient, httpUrlGenerator: CcoHttpUrlGenerator) {
    super('VerifiedEmailIdentity', http, httpUrlGenerator, {
      root: config.mailerBaseUrl,
      entityHttpResourceUrls: {
        VerifiedEmailIdentity: {
          collectionResourceUrl: '/identities',
          entityResourceUrl: '/identities',
        },
      },
    });
  }
}
