import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { config } from '@cco/apps/cco-frontend';
import { LayoutComponent } from '@app/core/containers/layout/layout.component';
import { LocationTrackerComponent } from '@app/core/containers/location-tracker/location-tracker.component';
import { CgccStompService } from '@app/core/services/cgcc-stomp.service';
import { TelephonyService } from '@app/core/services/telephony.service';
import { CustomerService } from '@app/main/customers/services/customer.service';
import { EmployeeService } from '@app/main/employees/services/employee.service';
import { TruckService } from '@app/main/trucks/services/truck.service';


import { CustomIconsModule } from '@app/modules/custom-icons/custom-icons.module';


import { LuxonModule } from '@app/modules/luxon';
import { LuxonDateAdapter, MAT_LUXON_DATE_FORMATS } from '@app/modules/luxon-date-adapter';

import { TruncateWithEllipsesPipe } from '@app/shared/pipes/truncate-with-ellipses.pipe';
import { AuthService } from '@core/auth/services/auth.service';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { CookieService } from 'ngx-cookie-service';
import { BreadcrumbsComponent } from './containers/breadcrumbs/breadcrumbs.component';
import {
  SideNavExpandableMenuComponent,
} from './containers/side-nav-expandable-menu/side-nav-expandable-menu.component';
import { SideNavMenuItemComponent } from './containers/side-nav-menu-item/side-nav-menu-item.component';
import { CoreRoutingModule } from './core-routing.module';
import { CcoEntityDataModule } from './data/cco-entity-data.module';
import { GoogleMapsApiLoader } from './services/google-maps-api-loader.service';
import { MediaStateSerializerService } from './services/media-state-serializer.service';
import { SettingsCoreModule } from '@main/settings/settings-core.module';
import { ENTITY_SERVICE } from './services/entity.service';
import { DefaultEntityService } from './services/default-entity.service';
import { ServiceWorkerUpdateService } from '@core/services/service-worker-update.service';

import { ErrorReportingService } from './services/error-reporting.service';

import {
  UpdateAvailableSnackComponent,
} from '@core/components/update-available-snack/update-available-snack.component';
import { IfHasPrivilegesDirective } from '@modules/auth-utils/directives/if-has-privileges.directive';
import { StatusBarComponent } from '@core/components/status-bar/status-bar.component';
import { xsrfInterceptor } from '@core/xsrf/xsrf.interceptor';
import { retryOnErrorInterceptor } from '@core/http/retry-on-error.interceptor';
import { httpErrorInterceptor } from '@core/http/http-error.interceptor';
import { sendCredentialsInterceptor } from '@core/http/send-credentials.interceptor';
import { showInvalidInputErrorDialogInterceptor } from '@core/http/show-invalid-input-error-dialog.interceptor';
import { redirectToLoginInterceptor } from '@core/http/redirect-to-login.interceptor';

const defaultDataServiceConfig = (): DefaultDataServiceConfig => ({
  root: config.apiBaseUrl,
  timeout: 10000, // request timeout
});

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    CoreRoutingModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    CcoEntityDataModule,
    CustomIconsModule,
    IfHasPrivilegesDirective,
    LuxonModule,
    SettingsCoreModule,
    StatusBarComponent,
    LayoutComponent,
    LocationTrackerComponent,
    SideNavMenuItemComponent,
    SideNavExpandableMenuComponent,
    BreadcrumbsComponent,
    TruncateWithEllipsesPipe,
    UpdateAvailableSnackComponent,
  ],
  providers: [
    // @ngrx/data
    { provide: DefaultDataServiceConfig, useFactory: defaultDataServiceConfig },
    CookieService,
    AuthService,
    // Auth2Service,
    ErrorReportingService,
    // Luxon adapter for MatDatepicker
    {
        provide: DateAdapter,
        useClass: LuxonDateAdapter,
        deps: [MAT_DATE_LOCALE],
    },
    /*
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig,
    },
    */
    {
        provide: MAT_DATE_FORMATS,
        useValue: MAT_LUXON_DATE_FORMATS,
    },
    GoogleMapsApiLoader,
    // To be moved into feature modules
    CustomerService,
    EmployeeService,
    MediaStateSerializerService,
    // PriceLevelService,
    {
        provide: ENTITY_SERVICE,
        useClass: DefaultEntityService,
    },
    CgccStompService,
    TelephonyService,
    TruckService,
    provideHttpClient(
      withInterceptorsFromDi(),
      /*
       * showInvalidInputErrorDialogInterceptor will process requests first and responses last
       * sendCredentialsInterceptor will process requests last and responses first
       */
      withInterceptors([
        showInvalidInputErrorDialogInterceptor,
        httpErrorInterceptor,
        redirectToLoginInterceptor,
        retryOnErrorInterceptor,
        xsrfInterceptor,
        sendCredentialsInterceptor,
      ]),
      withJsonpSupport(),
    ),
  ],
})
export class CoreModule {
  // Inject ServiceWorkerUpdateService here to load it near application startup
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, _serviceWorkerUpdateService: ServiceWorkerUpdateService, _errorReportingService: ErrorReportingService) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded.  Import only in AppModule.');
    }
  }
}
