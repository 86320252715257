import { NgModule, NgZone } from '@angular/core';
import { NavigationBehaviorOptions, Router, UrlTree } from '@angular/router';
import { EntityServices } from '@ngrx/data';

@NgModule()
export class E2eModule {
  constructor(router: Router, zone: NgZone, entityServices: EntityServices) {
    (window as any).navigateByUrl = (url: string | UrlTree, extras?: NavigationBehaviorOptions) => {
      return zone.run(() => router.navigateByUrl(url, extras));
    };

    (window as any).clearEntityCache = (entityName?: string) => {
      entityServices.getEntityCollectionService(entityName).clearCache();
    };
  }
}
