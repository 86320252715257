import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmDialogComponent } from '@modules/confirm-dialog/components/confirm-dialog/confirm-dialog.component';
import * as Sentry from '@sentry/browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerUpdateService {
  get eventLog$(): Observable<VersionEvent[]> {
    return this._eventLog$.pipe();
  }
  private _eventLog$ = new BehaviorSubject<VersionEvent[]>([]);
  private _eventLog: VersionEvent[] = [];

  get enabled() {
    return this.updates.isEnabled;
  }

  constructor(private updates: SwUpdate, protected snackBar: MatSnackBar, protected dialog: MatDialog) {
    updates.versionUpdates.subscribe((evt) => {
      this._eventLog.push(evt);
      this._eventLog$.next(this._eventLog);
    });
  }

  checkForUpdate(): Promise<boolean> {
    if (this.updates.isEnabled)
      return this.updates.checkForUpdate();

    return new Promise<boolean>(resolve => resolve(false));
  }

  updateApp() {
    this.update()
      .then((updated) => {
        if (updated) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              title: 'Activate New Version',
              message: 'Reload Concrete CloudOps™ to activate a new version?',
              button1: {
                label: 'Yes',
                icon: 'check',
                result: true,
              },
              button2: {
                label: 'Cancel',
                result: false,
                focusInitial: true,
              },
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              location.reload();
            }
          });
        } else {
          this.snackBar.open('An error occurred while updating.  Please hit reload in your browser or force stop and restart the app.', 'Close');
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        this.snackBar.open('An error occurred while updating.  Please hit reload in your browser or force stop and restart the app.', 'Close');
      });
  }

  update(): Promise<boolean> {
    if (this.updates.isEnabled)
      return this.updates.activateUpdate();
  }
}
