import { NgModule } from '@angular/core';
import { CcoEntityUrlRegistryService } from '@app/data/cco-entity-url-registry.service';
import { entityUrlMap } from '@app/store/entity-metadata';

@NgModule({})
export class CcoEntityUrlRegistryModule {
  constructor(entityUrlRegistry: CcoEntityUrlRegistryService) {
    entityUrlRegistry.registerEntityUrls(entityUrlMap);
  }
}
