import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { auth2Actions } from '@core/auth2/store/auth2.actions';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '@cco/apps/cco-frontend';

export function redirectToLoginInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const store = inject(Store);
  const appConfig = inject(APP_CONFIG);
  const apiBaseUrl = new URL(appConfig.apiBaseUrl, window.location.origin).toString();

  return next(req).pipe(
    catchError((err: any): Observable<HttpEvent<unknown>> => {
      const requestUrl = new URL(req.url, window.location.origin).toString();

      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 && requestUrl.startsWith(apiBaseUrl)) {
          store.dispatch(auth2Actions.authenticateUser());
          return EMPTY;
        }
      }

      throw err;
    }),
  );
}
