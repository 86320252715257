import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectSelectedCustomerId } from '@app/store/reducers';
import { CustomerService } from '@main/customers/services/customer.service';
import { combineLatest, of } from 'rxjs';
import { customerStatisticsApiActions } from '@main/customers/store/customer-statistics-api.actions';
import { customerPageActions } from '@main/customers/store/customer-page.actions';
import { notNullish } from '@app/shared/rxjs/operators/standard-operators';

export const getSumOfJobAmountsByCalendarYear$ = createEffect((
  actions$ = inject(Actions),
  customerService = inject(CustomerService),
  store = inject(Store),
) => {
  return combineLatest([
    actions$.pipe(ofType(customerPageActions.jobStatisticsTabOpened)),
    store.select(selectSelectedCustomerId).pipe(notNullish()),
  ]).pipe(
    switchMap(([, customerId ]) => customerService.getSumOfJobAmountsByCalendarYear$(customerId).pipe(
      map(statistics => customerStatisticsApiActions.getStatisticsSuccess({ customerId, statistics })),
      catchError(err => of(customerStatisticsApiActions.getStatisticsFailure({ err }))),
    )),
  );
}, { functional: true });
