import { Injectable } from '@angular/core';
import { IotThingActions, IotThingSelectors } from '@app/modules/iot-things/store';
import { PlcMixDesign } from '@cco/model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { MixerPlcService } from '../services/mixer-plc.service';
import * as MixerPlcActions from './mixer-plc.actions';

@Injectable()
export class MixerPlcEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private mixerPlcService: MixerPlcService,
  ) {}

  getIotGatewayThingDocument$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getIotGatewayThingDocument),
      switchMap(action =>
        this.mixerPlcService.getIotGatewayThingDocument(action.assetUuid).pipe(
          map(thingDocument => IotThingActions.upsertThing({ thing: thingDocument })),
          catchError(error => of(MixerPlcActions.getIotGatewayThingDocumentFailure({ error }))),
        ),
      ),
    ); },
  );

  getIotGatewayThingShadow$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getIotGatewayThingShadow),
      switchMap(action =>
        this.store.select(IotThingSelectors.selectThingNameByAssetUuidAndThingTypeName, {
          assetUuid: action.assetUuid,
          thingTypeName: 'cco-iot-gw-v1',
        }).pipe(
          tap((thingName) => {
            if (!thingName) {
              this.store.dispatch(
                MixerPlcActions.getIotGatewayThingDocument({ assetUuid: action.assetUuid }),
              );
            }
          }),
          filter(thingName => !!thingName),
          map(thingName =>
            IotThingActions.getThingShadow({
              assetType: 'mixer',
              assetUuid: action.assetUuid,
              thingName,
            }),
          ),
        ),
      ),
    ); },
  );

  getPlcGatewayThingDocument$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcGatewayThingDocument),
      switchMap(action =>
        this.mixerPlcService.getPlcGatewayThingDocument(action.assetUuid).pipe(
          map(thingDocument => IotThingActions.upsertThing({ thing: thingDocument })),
          catchError(error => of(MixerPlcActions.getPlcGatewayThingDocumentFailure({ error }))),
        ),
      ),
    ); },
  );

  getPlcGatewayThingShadow$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcGatewayThingShadow),
      switchMap(action =>
        this.store.select(IotThingSelectors.selectThingNameByAssetUuidAndThingTypeName, {
          assetUuid: action.assetUuid,
          thingTypeName: 'cco-plc-gw-v1',
        }).pipe(
          tap((thingName) => {
            if (!thingName) {
              this.store.dispatch(
                MixerPlcActions.getPlcGatewayThingDocument({ assetUuid: action.assetUuid }),
              );
            }
          }),
          filter(thingName => !!thingName),
          map(thingName =>
            IotThingActions.getThingShadow({
              assetType: 'mixer',
              assetUuid: action.assetUuid,
              thingName,
            }),
          ),
        ),
      ),
    ); },
  );

  getPlcCalibrationThingDocument$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcCalibrationThingDocument),
      switchMap(action =>
        this.mixerPlcService.getPlcCalibrationThingDocument(action.assetUuid).pipe(
          map(thingDocument => IotThingActions.upsertThing({ thing: thingDocument })),
          catchError(error =>
            of(MixerPlcActions.getPlcCalibrationThingDocumentFailure({ error })),
          ),
        ),
      ),
    ); },
  );

  getPlcConfigThingShadow$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcConfigThingShadow),
      switchMap(action =>
        this.store.select(IotThingSelectors.selectThingNameByAssetUuidAndThingTypeName, {
          assetUuid: action.assetUuid,
          thingTypeName: 'cco-plc-mixer-config-v1',
        }).pipe(
          tap((thingName) => {
            if (!thingName) {
              this.store.dispatch(
                MixerPlcActions.getPlcConfigThingDocument({ assetUuid: action.assetUuid }),
              );
            }
          }),
          filter(thingName => !!thingName),
          map(thingName =>
            IotThingActions.getThingShadow({
              assetType: 'mixer',
              assetUuid: action.assetUuid,
              thingName,
            }),
          ),
        ),
      ),
    ); },
  );

  getPlcConfigThingDocument$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcConfigThingDocument),
      switchMap(action =>
        this.mixerPlcService.getPlcConfigThingDocument(action.assetUuid).pipe(
          map(thingDocument => IotThingActions.upsertThing({ thing: thingDocument })),
          catchError(error => of(MixerPlcActions.getPlcConfigThingDocumentFailure({ error }))),
        ),
      ),
    ); },
  );

  getPlcCalibrationThingShadow$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcCalibrationThingShadow),
      switchMap(action =>
        this.store.select(IotThingSelectors.selectThingNameByAssetUuidAndThingTypeName, {
          assetUuid: action.assetUuid,
          thingTypeName: 'cco-plc-mixer-calibration-v1',
        }).pipe(
          tap((thingName) => {
            if (!thingName) {
              this.store.dispatch(
                MixerPlcActions.getPlcCalibrationThingDocument({ assetUuid: action.assetUuid }),
              );
            }
          }),
          filter(thingName => !!thingName),
          map(thingName =>
            IotThingActions.getThingShadow({
              assetType: 'mixer',
              assetUuid: action.assetUuid,
              thingName,
            }),
          ),
        ),
      ),
    ); },
  );

  getPlcMixDesignThingDocument$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcMixDesignThingDocument),
      switchMap(action =>
        this.mixerPlcService
          .getPlcMixDesignThingDocument(action.assetUuid, action.mixDesignSlot)
          .pipe(
            map(thingDocument => IotThingActions.upsertThing({ thing: thingDocument })),
            catchError(error =>
              of(MixerPlcActions.getPlcMixDesignThingDocumentFailure({ error })),
            ),
          ),
      ),
    ); },
  );

  getPlcMixDesignThingShadow$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcMixDesignThingShadow),
      switchMap(action =>
        this.store.select(IotThingSelectors.selectThingNamesByAssetUuidAndThingTypeName, {
          assetUuid: action.assetUuid,
          thingTypeName: 'cco-plc-mix-design-v1',
        }).pipe(
          map(thingNames =>
            thingNames?.find(thingName => thingName.endsWith(`-${action.mixDesignSlot}`)),
          ),
          tap((thingName) => {
            if (!thingName) {
              this.store.dispatch(
                MixerPlcActions.getPlcMixDesignThingDocument({
                  assetUuid: action.assetUuid,
                  mixDesignSlot: action.mixDesignSlot,
                }),
              );
            }
          }),
          filter(thingName => !!thingName),
          map(thingName =>
            IotThingActions.getThingShadow({
              assetType: 'mixer',
              assetUuid: action.assetUuid,
              thingName,
            }),
          ),
        ),
      ),
    ); },
  );

  updatePlcMixDesign$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.updateMixDesign),
      switchMap((action) => {
        const mixDesigns: { [mixDesignSlot: string]: PlcMixDesign } = {};
        mixDesigns[action.mixDesignSlot] = action.plcMixDesign;
        return this.mixerPlcService
          .updateMixDesign(action.assetUuid, action.mixDesignSlot, {
            state: {
              desired: {
                mixDesigns,
              },
            },
          })
          .pipe(
            map(update =>
              MixerPlcActions.updateMixDesignSuccess({
                assetUuid: action.assetUuid,
                mixDesignSlot: action.mixDesignSlot,
                update,
              }),
            ),
            catchError(error => of(MixerPlcActions.updateMixDesignFailure({ error }))),
          );
      }),
    ); },
  );

  getPlcMixDesignThingDocuments$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcMixDesignThingDocuments),
      switchMap(action =>
        this.mixerPlcService.getPlcMixDesignThingDocuments(action.assetUuid).pipe(
          map(thingDocuments => IotThingActions.upsertThings({ things: thingDocuments })),
          catchError(error =>
            of(MixerPlcActions.getPlcMixDesignThingDocumentsFailure({ error })),
          ),
        ),
      ),
    ); },
  );

  getPlcMixDesignThingShadows$: Observable<Action> = createEffect(() =>
    { return this.actions$.pipe(
      ofType(MixerPlcActions.getPlcMixDesignThingShadows),
      switchMap(action =>
        this.store.select(IotThingSelectors.selectThingNamesByAssetUuidAndThingTypeName, {
          assetUuid: action.assetUuid,
          thingTypeName: 'cco-plc-mix-design-v1',
        }).pipe(
          tap((thingNames) => {
            if (!thingNames || thingNames.length < 6) {
              this.store.dispatch(
                MixerPlcActions.getPlcMixDesignThingDocuments({
                  assetUuid: action.assetUuid,
                }),
              );
            }
          }),
          filter(thingNames => thingNames && thingNames.length > 0),
          map(thingNames =>
            IotThingActions.getThingShadows({
              assetType: 'mixer',
              assetUuid: action.assetUuid,
              thingNames,
            }),
          ),
        ),
      ),
    ); },
  );
}
