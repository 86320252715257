import {
  createActionGroup,
  createFeature,
  createReducer,
  createSelector,
  emptyProps,
  on,
  props,
  Selector
} from '@ngrx/store';
import { stateSanitizer } from '@app/store/reducers';

export const stateViewerActions = createActionGroup({
  source: 'StateViewer',
  events: {
    opened: emptyProps(),
    pageSelected: props<{ pageIndex: number }>(),
    selectChild: props<{ pathSegment: string }>(),
    selectPathSlice: props<{ lastIndex: number }>(),
    top: emptyProps(),
    up: emptyProps(),
  },
});

interface State {
  opened: boolean;
  selectedNodePath: string[];
  selectedNodePageIndices: number[];
}

const initialState: State = {
  opened: false,
  selectedNodePath: [],
  selectedNodePageIndices: [],
};

export const stateViewerFeature = createFeature({
  name: 'StateViewer',
  reducer: createReducer(initialState,
    on(stateViewerActions.opened, (state): State => ({
      ...state,
      opened: true,
    })),
    on(stateViewerActions.top, (state): State => ({
      ...state,
      selectedNodePath: [],
      selectedNodePageIndices: [],
    })),
    on(stateViewerActions.up, (state): State => ({
      ...state,
      selectedNodePath: [ ...state.selectedNodePath.slice(0, state.selectedNodePath.length - 1) ],
      selectedNodePageIndices: [ ...state.selectedNodePageIndices.slice(0, state.selectedNodePageIndices.length - 1) ],
    })),
    on(stateViewerActions.pageSelected, (state, { pageIndex }): State => ({
      ...state,
      selectedNodePageIndices: [ ...state.selectedNodePageIndices.slice(0, state.selectedNodePageIndices.length - 1), pageIndex ],
    })),
    on(stateViewerActions.selectChild, (state, { pathSegment }): State => ({
      ...state,
      selectedNodePath: [ ...state.selectedNodePath, pathSegment ],
      selectedNodePageIndices: [ ...state.selectedNodePageIndices, 0 ],
    })),
    on(stateViewerActions.selectPathSlice, (state, { lastIndex }): State => ({
      ...state,
      selectedNodePath: [ ...state.selectedNodePath.slice(0, lastIndex + 1) ],
      selectedNodePageIndices: [ ...state.selectedNodePageIndices.slice(0, lastIndex + 1) ],
    })),
  ),
});

const selectStoreState: Selector<any, any> = state => stateSanitizer(state);

export const selectSelectedNode = createSelector(selectStoreState, stateViewerFeature.selectSelectedNodePath, (state, selectedNodePath) => {
  if (state == null)
    return undefined;

  let node = state;
  for (const pathSegment of selectedNodePath) {
    if (pathSegment in node)
      node = node[pathSegment];
    else
      return undefined;
  }

  return node;
});

const stateObjectKeySorter = (a: string, b: string) => {
  const intA = parseInt(a);
  const intB = parseInt(b);
  if (!isNaN(intA) && !isNaN(intB))
    return intA - intB;

  return a.localeCompare(b);
};

function sortedNodeKeys(node: any) {
  if (Array.isArray(node))
    Object.keys(node);

  return Object.keys(node).sort(stateObjectKeySorter);
}

export const selectSelectedNodeView = createSelector(selectSelectedNode, (node) => {
  const nodeView = {};
  for (const key of sortedNodeKeys(node)) {
    const value = node[key];
    const valueToString = Object.prototype.toString.call(value);
    switch (valueToString) {
      case '[object String]':
        nodeView[key] = value;
        break;
      case '[object Number]':
        nodeView[key] = value;
        break;
      default:
        nodeView[key] = valueToString;
        break;
    }
  }
  return nodeView;
});

export interface NodeEntry {
  key: string;
  type: string;
  value: string | number;
}

function viewValue(value: any) {
  const valueToString = Object.prototype.toString.call(value);
  switch (valueToString) {
    case '[object Array]':
      return `[${value.length}]`;
    case '[object Boolean]':
      return value;
    case '[object Number]':
      return value;
    case '[object Object]':
      return `{${Object.keys(value).length}}`;
    case '[object String]':
      return value;
    default:
      return valueToString;
  }
}

export const selectSelectedNodePath = stateViewerFeature.selectSelectedNodePath;
export const selectSelectedNodeViewType = createSelector(selectSelectedNodeView, nodeView => Object.prototype.toString.call(nodeView));
export const selectSelectedNodeEntries = createSelector(selectSelectedNode, (node) => {
  if (node == null)
    return [];

  const entries: NodeEntry[] = [];
  for (const key of sortedNodeKeys(node)) {
    entries.push({
      key,
      type: Object.prototype.toString.call(node[key]),
      value: viewValue(node[key]),
    });
  }
  return entries;
});
export const selectSelectedNodePageIndex = createSelector(stateViewerFeature.selectSelectedNodePageIndices, pageIndices => pageIndices.length > 0 ? pageIndices[pageIndices.length - 1] : 0);
export const selectSelectedNodePagedEntries = createSelector(selectSelectedNodeEntries, selectSelectedNodePageIndex, (entries, pageIndex) => {
  return entries.slice(100 * pageIndex, 100 * (pageIndex + 1));
});
export const selectSelectedNodeEntriesLength = createSelector(selectSelectedNodeEntries, entries => entries.length);
