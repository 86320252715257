import { Component, Input, OnInit } from '@angular/core';
import { Breadcrumb } from '@app/shared/utils/route-utils';
import * as fromRoot from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TruncateWithEllipsesPipe } from '@app/shared/pipes/truncate-with-ellipses.pipe';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatAnchor } from '@angular/material/button';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        MatAnchor,
        RouterLink,
        MatIcon,
        AsyncPipe,
        TruncateWithEllipsesPipe,
    ],
})
export class BreadcrumbsComponent implements OnInit {
  @Input()
  maxSegments: number;

  @Input()
  maxLabelSegmentLength: number;

  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.breadcrumbs$ = this.store.select(fromRoot.selectRouterRouteData).pipe(
      untilDestroyed(this),
      map((routeData) => {
        const breadcrumbs: Breadcrumb[] = [];

        routeData
          .filter(rData => !!rData.breadcrumbs)
          .map(rData => rData.breadcrumbs)
          .forEach((bcrumbs) => {
            breadcrumbs.push(...bcrumbs);
          });

        return breadcrumbs;
      }),
      withLatestFrom(this.store.select((fromRoot.selectRouterFlatParams))),

      // Substitute actual route parameters
      map(([breadcrumbs, flatParams]) => {
        return breadcrumbs.map(breadcrumb => ({
          ...breadcrumb,
          path: breadcrumb.path.map((pathSegment) => {
            if (/:[a-z][a-zA-Z0-9]+/.test(pathSegment)) {
              return flatParams[pathSegment.slice(1)];
            }

            return pathSegment;
          }),
        }));
      }),

      // Populate labels from the store
      map((breadcrumbs) => {
        return breadcrumbs.map(breadcrumb => ({
          ...breadcrumb,
          label$: breadcrumb.labelSelector
            ? this.store.select(breadcrumb.labelSelector).pipe(

                map(label => `${label}`),
                untilDestroyed(this))
            : null,
        }));
      }),

      // Populate the router links
      map((breakcrumbs) => {
        const currentLink = [];

        return breakcrumbs.map((breadcrumb) => {
          currentLink.push(...breadcrumb.path);
          return {
            ...breadcrumb,
            routerLink: [...currentLink],
          };
        });
      }),
    );
  }
}
