import { NgModule } from '@angular/core';
import { LiveBatchDataService } from '@app/main/trucks/services/live-batch-data.service';
import { PlcBatchRecordDataService } from '@modules/plc-batch-record/services/plc-batch-record.data-service';
import { EntityDataService } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { MultiEntityEffects } from '@app/data/multi-entity.effects';
import { VerifiedEmailIdentityService } from '@app/shared/services/verified-email-identity.service';
import { EmailCampaignService } from '@app/shared/services/email-campaign.service';
import { RegisteredEmailCampaignService } from '@app/shared/services/registered-email-campaign.service';
import { GoogleCalendarDataService } from '@core/services/google-calendar.data-service';
import { CcoEntityUrlRegistryModule } from '@core/data/cco-entity-url-registry.module';

@NgModule({
  imports: [
    EffectsModule.forFeature([MultiEntityEffects]),
    CcoEntityUrlRegistryModule,
  ],
  providers: [LiveBatchDataService, PlcBatchRecordDataService],
})
export class CcoEntityDataModule {
  constructor(
    emailCampaignService: EmailCampaignService,
    entityDataService: EntityDataService,
    googleCalendarDataService: GoogleCalendarDataService,
    liveBatchDataService: LiveBatchDataService,
    plcBatchRecordDataService: PlcBatchRecordDataService,
    registeredEmailCampaignService: RegisteredEmailCampaignService,
    verifiedEmailIdentityService: VerifiedEmailIdentityService,
  ) {

    entityDataService.registerService('EmailCampaign', emailCampaignService);
    entityDataService.registerService('GoogleCalendar', googleCalendarDataService);
    entityDataService.registerService('LiveBatch', liveBatchDataService);
    entityDataService.registerService('PlcBatchRecord', plcBatchRecordDataService);
    entityDataService.registerService('RegisteredEmailCampaign', registeredEmailCampaignService);
    entityDataService.registerService('VerifiedEmailIdentity', verifiedEmailIdentityService);
  }
}
