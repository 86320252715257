import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'unauthorized',
    loadChildren: () =>
      import('@modules/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
  },
  // { path: '**', loadChildren: () => import('@modules/not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 56],
    enableTracing: false
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
