<mat-card>
  <mat-card-header>
    <mat-card-title>
      <div class="flex flex-row items-center gap-2">
        <mat-icon class="flex-grow-0" fontSet="material-symbols-outlined">account_tree</mat-icon> <div class="flex-grow-0">State Viewer</div>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="slim">
    <mat-divider class="mb-2"></mat-divider>
    @let entriesLength = selectedNodeEntriesLength$ | async;
    @let pageIndex = selectedNodePageIndex$ | async;
    <div class="flex flex-row gap-0.5 items-center">
      <button mat-icon-button (click)="selectStateNodeSlice(-1)"><mat-icon fontSet="material-symbols-outlined">home</mat-icon></button>
      @for (pathSegment of selectedNodePath$ | async; track pathSegment; let i = $index, first = $first, last = $last) {
        @if (first) {
          <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
        }
        <button mat-button (click)="selectStateNodeSlice(i)">{{ pathSegment }}</button>
        @if (!last) {
          <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
        } @else {
          @if (entriesLength > 100) {
            [
              {{ 100 * pageIndex + 1 }}..{{ 100 * (pageIndex + 1) + 1 }} of {{ entriesLength }}
              <mat-slider [min]="0" [max]="entriesLength - 1" [step]="100" discrete [displayWith]="pageSliderDisplayFn">
                <input matSliderThumb value="{{ 100 * pageIndex }}" (valueChange)="selectPage($event / 100)">
              </mat-slider>
            ]
          }
          @if ((selectedNodeType$ | async) === '[object Object]') {
            <mat-icon fontSet="material-symbols-outlined">chevron_right</mat-icon>
            <input matInput class="border p-0.5" (change)="selectStateNodeChild($event.target.value)" cdkFocusInitial>
          }
        }
      }
    </div>
    <div class="bg-gray-100 p-2 overflow-auto">
      <ul>
        @for (entry of selectedNodeEntries$ | async; track entry.key) {
          <li class="font-mono flex flex-row justify-start items-center gap-2">
            <span class="flex-grow-0">{{ entry.key }}:</span>
            @switch (entry.type) {
              @case ('[object Array]') {
                {{ entry.value }}
                <div class="flex-grow-0">
                  <button mat-icon-button class="super-slim" (click)="selectStateNodeChild(entry.key)"><mat-icon fontSet="material-symbols-outlined">arrow_forward</mat-icon></button>
                </div>
              }
              @case ('[object Boolean]') {
                {{ entry.value }}
              }
              @case ('[object Object]') {
                {{ entry.value }}
                <button mat-icon-button class="super-slim" (click)="selectStateNodeChild(entry.key)"><mat-icon fontSet="material-symbols-outlined">arrow_forward</mat-icon></button>
              }
              @case ('[object String]') {
                "{{ entry.value }}"
              }
              @case ('[object Number]') {
                {{ entry.value }}
              }
              @case ('[object Null]') {
                null
              }
              @default {
                {{ entry.type }}
              }
            }
          </li>
        }
      </ul>
    </div>
  </mat-card-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
  </mat-dialog-actions>
</mat-card>
