import { Inject, Injectable, Optional } from '@angular/core';
import { ChangeSet, DefaultDataServiceConfig, EntityCacheDataService, EntityDefinitionService } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CcoHttpUrlGenerator } from '@app/store/cco-http-url-generator';
import { catchError, delay, map, switchMap, timeout } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@cco/apps/cco-frontend';

@Injectable()
export class CcoEntityCacheDataService extends EntityCacheDataService {
  constructor(
    protected entityDefinitionService: EntityDefinitionService,
    protected http: HttpClient,
    protected httpUrlGenerator: CcoHttpUrlGenerator,
    @Inject(APP_CONFIG) protected appConfig: AppConfig,
    @Optional() config?: DefaultDataServiceConfig,
  ) {
    super(entityDefinitionService, http, config);
  }

  saveEntities(changeSet: ChangeSet, url: string): Observable<ChangeSet> {
    if (changeSet.changes.length === 1 && changeSet.changes[0].op === 'Upsert') {
      changeSet = this.filterChangeSet(changeSet);
      // Assume server doesn't understand @ngrx/entity Update<T> structure;
      // Extract the entity changes from the Update<T>[] and restore on the return from server
      changeSet = this.flattenUpdates(changeSet);

      const change = changeSet.changes[0];

      let result$: Observable<ChangeSet> = this.httpUrlGenerator.collectionResource$(change.entityName, this.appConfig.apiBaseUrl).pipe(
        switchMap(url => this.http.put<any[]>(url, change.entities)),
        map(result => this.restoreUpdates({ ...changeSet, changes: [{ ...change, entities: result }] })),
        catchError(this.handleError({ method: 'POST', url, data: changeSet })),
      );

      if (this.timeout) {
        result$ = result$.pipe(timeout(this.timeout));
      }

      if (this.saveDelay) {
        result$ = result$.pipe(delay(this.saveDelay));
      }

      return result$;
    }

    return super.saveEntities(changeSet, url);
  }
}
