import { DefaultDataService, DefaultDataServiceConfig, EntityCollectionDataService, QueryParams } from '@ngrx/data';
import { Injectable, OnDestroy, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CcoHttpUrlGenerator } from '@app/store/cco-http-url-generator';
import { Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Update } from '@ngrx/entity';

@Injectable()
export class CcoDefaultDataService<T> extends DefaultDataService<T> implements OnDestroy {
  protected entityUrl$: Observable<string>;
  protected entitiesUrl$: Observable<string>;
  protected destroyed$ = new Subject<void>();

  constructor(entityName: string, protected http: HttpClient, protected httpUrlGenerator: CcoHttpUrlGenerator, config?: DefaultDataServiceConfig) {
    super(entityName, http, httpUrlGenerator, config);

    const { root = 'api' } = config || {};

    this.entityUrl$ = this.httpUrlGenerator.entityResource$(entityName, root);
    this.entitiesUrl$ = this.httpUrlGenerator.collectionResource$(entityName, root);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  add(entity: T): Observable<T> {
    const entityOrError =
      entity || new Error(`No "${this.entityName}" entity to add`);
    return this.entityUrl$.pipe(
      switchMap(entityUrl => this.execute('POST', entityUrl, entityOrError)));
  }

  delete(key: number | string): Observable<number | string> {
    let err: Error | undefined;
    if (key == null) {
      err = new Error(`No "${this.entityName}" key to delete`);
    }
    return this.entityUrl$.pipe(
      switchMap(entityUrl => this.execute('DELETE', entityUrl + key, err).pipe(
        // forward the id of deleted entity as the result of the HTTP DELETE
        map(() => key as number | string),
      )));
  }

  getAll(): Observable<T[]> {
    return this.entitiesUrl$.pipe(
      switchMap(entitiesUrl => this.execute('GET', entitiesUrl)));
  }

  getById(key: number | string): Observable<T> {
    let err: Error | undefined;
    if (key == null) {
      err = new Error(`No "${this.entityName}" key to get`);
    }
    return this.entityUrl$.pipe(
      switchMap(entityUrl => this.execute('GET', entityUrl + key, err)));
  }

  getWithQuery(queryParams: QueryParams | string): Observable<T[]> {
    const qParams =
      typeof queryParams === 'string'
        ? { fromString: queryParams }
        : { fromObject: queryParams };
    const params = new HttpParams(qParams);
    return this.entitiesUrl$.pipe(
      // eslint-disable-next-line no-undefined
      switchMap(entitiesUrl => this.execute('GET', entitiesUrl, undefined, { params })));
  }

  update(update: Update<T>): Observable<T> {
    const id = update && update.id;
    const updateOrError =
      id == null
        ? new Error(`No "${this.entityName}" update data or id`)
        : update.changes;
    return this.entityUrl$.pipe(
      switchMap(entityUrl => this.execute('PUT', entityUrl + id, updateOrError)));
  }

  // Important! Only call if the backend service supports upserts as a POST to the target URL
  upsert(entity: T): Observable<T> {
    const entityOrError =
      entity || new Error(`No "${this.entityName}" entity to upsert`);
    return this.entityUrl$.pipe(
      switchMap(entityUrl => this.execute('POST', entityUrl, entityOrError)));
  }

  upsertMany(entities: T[]): Observable<T[]> {
    const entityOrError =
      entities || new Error(`No "${this.entityName}" entities to upsert`);
    return this.entityUrl$.pipe(
      switchMap(entityUrl => this.execute('PUT', entityUrl, entityOrError)));
  }
}

@Injectable()
export class CcoDefaultDataServiceFactory {
  constructor(
    protected http: HttpClient,
    protected httpUrlGenerator: CcoHttpUrlGenerator,
    @Optional() protected config?: DefaultDataServiceConfig
  ) {
    config = config || {};
    httpUrlGenerator.registerHttpResourceUrls(config.entityHttpResourceUrls);
  }

  /**
   * Create a default {EntityCollectionDataService} for the given entity type
   * @param entityName {string} Name of the entity type for this data service
   */
  create<T>(entityName: string): EntityCollectionDataService<T> {
    return new CcoDefaultDataService<T>(
      entityName,
      this.http,
      this.httpUrlGenerator,
      this.config
    );
  }
}
