import { inject, Injectable } from '@angular/core';
import { AmplifyService } from '@app/core/services/amplify.service';
import { MessageAndTopic, ThingShadow } from '@cco/model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import {
  getThingShadow,
  getThingShadowFailure,
  getThingShadows,
  getThingShadowsFailure,
  upsertThingShadow,
} from './iot-thing.actions';
import { notNullish } from '@app/shared/rxjs/operators/standard-operators';
import { userTenantFeature } from '@core/user-tenant/user-tenant.reducer';
import { concatLatestFrom } from '@ngrx/operators';

@Injectable()
export class IotThingEffects {
  private readonly store = inject(Store);
  private readonly tenantId$ = this.store.select(userTenantFeature.selectSelectedTenantId).pipe(
    notNullish(),
  );

  constructor(
    private actions$: Actions,
    private amplifyService: AmplifyService,
  ) {}

  getThingShadow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getThingShadow),
      concatLatestFrom(() => this.tenantId$),
      mergeMap(([ action, tenantId ]) => {
        return combineLatest([
          this.amplifyService
            .subscribe$<
              // eslint-disable-next-line @typescript-eslint/ban-types
              ThingShadow<object>
            >(
              `ccov1-shadows/${ tenantId }/${ action.assetType }/${ action.assetUuid }/${ action.thingName }/get/accepted`,
            )
            .pipe(
              take(1),
              map((messageAndTopic) => {
                return upsertThingShadow({
                  thingName: action.thingName,
                  shadow: messageAndTopic.message,
                });
              }),
              catchError((error) => {
                return of(getThingShadowFailure({ error }));
              }),
            ),
          this.amplifyService.publish$(
            `ccov1-shadows/${ tenantId }/${ action.assetType }/${ action.assetUuid }/${ action.thingName }/get`,
            {},
          ),
        ]).pipe(map(([ subscribeResultAction ]) => subscribeResultAction));
      }),
    );
  });

  getThingShadows$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getThingShadows),
      concatLatestFrom(() => this.tenantId$),
      mergeMap(([action, tenantId]) => {
        return combineLatest([
          ...action.thingNames.map(thingName =>
            this.amplifyService
              // eslint-disable-next-line @typescript-eslint/ban-types
              .subscribe$<ThingShadow<object>>(
                `ccov1-shadows/${tenantId}/${action.assetType}/${action.assetUuid}/${thingName}/get/accepted`,
              )
              .pipe(
                take(1),
                // eslint-disable-next-line @typescript-eslint/ban-types
                map((messageAndTopic: MessageAndTopic<ThingShadow<object>>) => {
                  return upsertThingShadow({ thingName, shadow: messageAndTopic.message });
                }),
                catchError((error) => {
                  return of(getThingShadowsFailure({ error }));
                }),
              ),
          ),
          this.amplifyService.publish$(
            action.thingNames.map(
              thingName =>
                `ccov1-shadows/${tenantId}/${action.assetType}/${action.assetUuid}/${thingName}/get`,
            ),
            {},
          ),
        ]);
      }),
      switchMap((actions) => {
        // TODO: do not dispatch multiple actions
        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
        return actions.slice(0, actions.length - 1) as Action[];
      }),
    );
  });
}
