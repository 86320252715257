import { Component, NgZone, OnInit } from '@angular/core';
import { CgccStompService } from '@app/core/services/cgcc-stomp.service';
import * as Sentry from '@sentry/angular';
import { TimeAgoPipe } from '../../../modules/time-ago-pipe/pipes/time-ago-pipe';
import { FormatDistancePipe } from '../../../modules/format-distance-pipe/pipes/format-distance.pipe';
import { MatIcon } from '@angular/material/icon';
import { DecimalPipe, NgIf } from '@angular/common';
import { MatIconButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatTooltip } from '@angular/material/tooltip';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

export enum GpsStatuses {
  UNKNOWN = 'Unknown',
  GOOD_FIX = 'Good fix (<= 100m)',
  BAD_FIX = 'Bad fix (> 100m)',
  ERROR = 'Error',
}

@Component({
    selector: 'app-location-tracker',
    templateUrl: './location-tracker.component.html',
    styleUrls: ['./location-tracker.component.scss'],
    standalone: true,
    imports: [
        MatMenu,
        MatMenuItem,
        MatTooltip,
        MatDivider,
        MatIconButton,
        MatMenuTrigger,
        NgIf,
        MatIcon,
        DecimalPipe,
        FormatDistancePipe,
        TimeAgoPipe,
    ],
})
export class LocationTrackerComponent implements OnInit {
  gpsStatus: GpsStatuses = GpsStatuses.UNKNOWN;
  GpsStatuses = GpsStatuses;
  latestPos: GeolocationPosition;

  constructor(protected ngZone: NgZone, protected stompService: CgccStompService) {}

  ngOnInit() {
    navigator.geolocation.watchPosition(
      (pos: GeolocationPosition) => {
        this.latestPos = pos;
        if (pos.coords.accuracy <= 100) {
          this.gpsStatus = GpsStatuses.GOOD_FIX;
        } else {
          this.gpsStatus = GpsStatuses.BAD_FIX;
        }
      },
      (err: GeolocationPositionError) => {
        Sentry.captureException(err);
        this.gpsStatus = GpsStatuses.ERROR;
      },
      {
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 60000,
      },
    );
  }
}
