<ng-container *ngIf="(breadcrumbs$ | async) as breadcrumbs">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
    <a *ngIf="!maxSegments || (breadcrumbs.length - i <= maxSegments)"
       style="min-width: 0; padding: 0"
       mat-button
       [routerLink]="breadcrumb.routerLink">
      <ng-container *ngIf="breadcrumb.label$ else staticLabel">{{breadcrumb.label$ | async | truncateWithEllipses:maxLabelSegmentLength }}</ng-container>
      <ng-template #staticLabel>{{breadcrumb.label | truncateWithEllipses:maxLabelSegmentLength }}</ng-template>
      <ng-container *ngIf="i < breadcrumbs.length - 1"> <mat-icon style="vertical-align: middle">chevron_right</mat-icon></ng-container>
    </a>
  </ng-container>
</ng-container>
