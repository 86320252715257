import { ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular';
import { DataServiceError } from '@ngrx/data';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export class WrappingSentryErrorHandler extends ErrorHandler {
  private readonly sentryErrorHandler: SentryErrorHandler;
  constructor(config?: ErrorHandlerOptions) {
    super();

    this.sentryErrorHandler = Sentry.createErrorHandler(config);
  }

  handleError(error: any) {
    Sentry.addBreadcrumb({
      level: 'info',
      message: 'Error reporting stack',
      data: {
        errorConstructorName: error?.constructor?.name,
        errorErrorKeys: (error?.error != null && typeof error.error === 'object') ? Object.keys(error.error) : [],
        errorErrorErrorType: error?.error?.error != null ? Object.prototype.toString.call(error.error.error) : null,
        errorErrorErrorKeys: (error?.error?.error != null && typeof error.error.error === 'object') ? Object.keys(error.error.error) : [],
        errorErrorTextType: error?.error?.text != null ? Object.prototype.toString.call(error.error.text) : null,
        errorErrorTextStart: (error?.error?.text != null && typeof error.error.text === 'string') ? error.error.text.slice(0, 100) : null,
        errorErrorTextEnd: (error?.error?.text != null && typeof error.error.text === 'string') ? error.error.text.slice(Math.max(0, error.error.text.length - 100), error.error.text.length) : null,
        errorKeys: (error != null && typeof error === 'object') ? Object.keys(error) : [],
        errorName: error?.name,
        headerKeys: error?.headers instanceof HttpHeaders ? (error.headers as HttpHeaders).keys() : [],
      },
    });

    if (error instanceof DataServiceError) {
      if (error.error instanceof HttpErrorResponse)
        return;

      if (isTimeout(error)) {
        Sentry.addBreadcrumb({
          level: 'warning',
          type: '@ngrx/data',
          category: 'timeout',
          data: error,
        });
        return;
      }
    }

    this.sentryErrorHandler.handleError(error);
  }
}

export function ngErrorExtractor(error: unknown, defaultExtractor: (error: unknown) => unknown): unknown {
  if (error instanceof HttpErrorResponse)
    return error;

  return defaultExtractor(error);
}

function isTimeout(message: any): boolean {
  return message?.error?.error?.name === 'TimeoutError'
    || message?.error?.message === 'Timeout has occurred';
}
