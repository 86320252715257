import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import {
  ConnectionMonitorService,
  ConnectionQuality,
  ConnectionStatistics
} from '@modules/connection-monitor/services/connection-monitor.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { NgIf, DecimalPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatDivider } from '@angular/material/divider';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@UntilDestroy()
@Component({
    selector: 'app-connection-monitor',
    templateUrl: 'connection-monitor.component.html',
    styleUrls: ['./connection-monitor.component.scss'],
    standalone: true,
    imports: [
        MatMenu,
        MatMenuItem,
        MatDivider,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatTooltip,
        MatCellDef,
        MatCell,
        NgIf,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatButton,
        MatMenuTrigger,
        MatIcon,
        DecimalPipe,
    ],
})
export class ConnectionMonitorComponent implements OnInit {
  connectionQuality: ConnectionQuality;
  ConnectionQuality = ConnectionQuality;
  connectionQualityDataSource: MatTableDataSource<ConnectionStatistics> = new MatTableDataSource<ConnectionStatistics>();
  displayColumns: string[] = ['window', 'meanRtt', 'stdDev', 'dropRate'];

  constructor(protected connectionMonitorService: ConnectionMonitorService) {}

  ngOnInit() {
    this.connectionMonitorService.connectionQuality$
      .pipe(untilDestroyed(this))
      .subscribe(connectionQuality => this.connectionQuality = connectionQuality);
    this.connectionMonitorService.connectionQualityDataSource$
      .pipe(untilDestroyed(this))
      .subscribe(data => this.connectionQualityDataSource.data = data);
  }

  reconnect() {
    this.connectionMonitorService.reconnect();
  }
}
